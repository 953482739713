import React, { useState, useEffect } from "react";
import { GET_CONFERENCE } from "../graphql/query";
import { useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import {
  ADD_TO_RESOURCE,
  INSERT_USER_REWARD_RESOURCE,
  INSERT_USER_REWARD_VIDEO,
  INSERT_USER_VISITS,
} from "../graphql/mutation";
import { pointsState } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import Loader from "./children/Loader";
import Agenda from "./children/Agenda";
import CustIcon from "./children/Svgs";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const MeetingRoom = () => {
  const { t } = useTranslation();
  let isCalled = false;
  const [points] = useRecoilState(pointsState);
  const [isAgendaOpen, setIsAgendaOpen] = useState(false);
  const {
    errors: errorsMeeting,
    data: dataMeeting,
    loading: loadingMeeting,
  } = useQuery(GET_CONFERENCE, {
    variables: {
      type: "meeting",
    },
    fetchPolicy: "cache-and-network",
  });
  const [storeToMyResource, { loading: loadingMeetingResource }] =
    useMutation(ADD_TO_RESOURCE);
  const [insertUserRewardVideo] = useMutation(INSERT_USER_REWARD_VIDEO);

  const [insertUserRewardResource] = useMutation(INSERT_USER_REWARD_RESOURCE);
  const [insertUserVisit] = useMutation(INSERT_USER_VISITS);

  useEffect(() => {
    if (dataMeeting) {
      insertUserVisit({
        variables: {
          conference_id: dataMeeting.conference[0].id,
          type: "Meeting",
        },
      });
    }
  }, [dataMeeting]);

  if (errorsMeeting) {
    return <div>{t("Something went wrong")}</div>;
  }

  const handleAddToBag = async (resource_id, points_agenda) => {
    storeToMyResource({
      variables: {
        resource_id,
      },
    });
    try {
      await insertUserRewardResource({
        variables: {
          resource_id,
          points: points + points_agenda,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const videoTimeUpdate = async (event) => {
    console.log(points);
    const ct = parseInt(event.currentTarget.currentTime);
    if (
      ct >= dataMeeting.conference[0].playlists[0].playlist_videos[0].give_at
    ) {
      if (!isCalled) {
        console.log(points);
        isCalled = true;
        try {
          await insertUserRewardVideo({
            variables: {
              points:
                dataMeeting.conference[0].playlists[0].playlist_videos[0]
                  .points + points,
              video_id:
                dataMeeting.conference[0].playlists[0].playlist_videos[0].id,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const openAgendaTab = () => {
    setIsAgendaOpen(true);
  };

  const closeAgendaTab = () => {
    setIsAgendaOpen(false);
  };

  const openFullscreen = () => {
    var elem = document.getElementById("sessionVideo");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  return (
    <section className="loadTansection meetingRoomPage h-screen flex justify-center items-start overflow-hidden">
      <video
        loop
        autoPlay
        muted
        name="videoFrame"
        className="videoFrame object-cover"
      >
        <source
          src={`${REACT_APP_CLOUDFRONT_URL}/videos/mahatma/mahatma.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="seminarvideo">
        {!dataMeeting && loadingMeeting ? (
          <Loader />
        ) : (
          dataMeeting && (
            <>
              {dataMeeting?.conference[0]?.playlists[0]?.playlist_videos[0]
                ?.url ? (
                <>
                  <div className="fullScreanBtn" onClick={openFullscreen}>
                    <CustIcon type="full-screen" />
                  </div>

                  <video
                    onTimeUpdate={videoTimeUpdate}
                    key={
                      dataMeeting.conference.length &&
                      dataMeeting.conference[0].playlists[0].playlist_videos[0]
                        .url
                    }
                    id="sessionVideo"
                    autoPlay
                    loop
                    controls={false}
                  >
                    <source
                      src={
                        dataMeeting.conference.length &&
                        dataMeeting.conference[0].playlists[0]
                          .playlist_videos[0].url
                      }
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <video
                  id="sessionVideo"
                  autoPlay
                  muted={true}
                  loop
                  controls={false}
                >
                  <source
                    src={`${REACT_APP_CLOUDFRONT_URL}/videos/mahatma/playlist/default.mp4`}
                    type="video/mp4"
                  />
                </video>
              )}
            </>
          )
        )}
      </div>

      <div className="rightAction">
        <ul>
          <li>
            <button
              onClick={openAgendaTab}
              disabled={loadingMeeting || !dataMeeting.conference.length}
            >
              {t("Agenda")}
            </button>
          </li>
        </ul>
      </div>
      {isAgendaOpen && (
        <Agenda
          src={dataMeeting?.conference[0]?.resources[0]?.url}
          onCloseAgendaTab={closeAgendaTab}
          handleAddToBag={() =>
            handleAddToBag(
              dataMeeting?.conference[0]?.resources[0]?.id,
              dataMeeting?.conference[0]?.resources[0]?.points
            )
          }
          loading={loadingMeetingResource}
          loadingdata={loadingMeeting}
        />
      )}
    </section>
  );
};

export default MeetingRoom;
