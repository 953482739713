import React from "react";
import EnglishFloor from "./children/EnglishFloor";
import FrenchFloor from "./children/FrenchFloor";
import RussianFloor from "./children/RussianFloor";

const ExhibitionFloor = ({ match }) => {
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  return (
    <>
      {code === "en" && <EnglishFloor match={match} />}
      {code === "fr" && <FrenchFloor />}
      {code === "ru" && <RussianFloor match={match} />}
    </>
  );
};

export default ExhibitionFloor;
