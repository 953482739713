import React from "react";
import Loader from "./Loader";

const VideoPopUp = ({
  src,
  onCloseVideoModal,
  loading,
  onVideoEnded,
  title,
}) => {
  return (
    <div className="fixed videoPopModel model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onCloseVideoModal}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {loading ? (
            <Loader />
          ) : (
            src && (
              <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
                <div className="close" onClick={onCloseVideoModal}>
                  <span></span>
                  <span></span>
                </div>
                <div className="sm:flex flex-col sm:items-start">
                  <div className="popup-header border-b border-solid w-full">
                    <h3>{title}</h3>
                  </div>
                  <div className="popup-body w-full">
                    <video
                      onEnded={onVideoEnded}
                      width="100%"
                      autoPlay
                      height="100%"
                      src={src}
                      controls={false}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default VideoPopUp;
