import React from "react";
import CustIcon from "./Svgs";

const Coin = ({ className, points }) => {
  const addClassName = className === undefined ? "" : className;
  return (
    <div className={`coinCount ${addClassName} `}>
      <div className="coinImgSec">
        <CustIcon type="coin" size="lg" />
      </div>
      <div className="coinCount">{points}</div>
    </div>
  );
};

export default Coin;
