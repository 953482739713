import React from 'react';

function ExpoLounge() {
    
    return (
        <section className="loadTansection flex justify-center expoLoungePage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
            <div className="container mx-auto px-5 py-8">
                <div className="relax-container grid grid-cols-1 md:grid-cols-4">
                    <div className="newsDetail md:col-span-2 pb-6" >
                        {/* <img alt="" src={require("../images/news1.jpg")} /> */}
                    </div>
                    <div className=" md:col-span-2 newsstand grid grid-flow-col grid-cols-3">
                        {/* <a href="" className="link1 nlink1"><img alt="" src={require("../images/news1.png")} /></a>
                        <a href="" className="link1 nlink2"><img alt="" src={require("../images/news2.png")} /></a>
                        <a href="" className="link1 nlink3"><img alt="" src={require("../images/news3.png")} /></a> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ExpoLounge;

// {require('../images/old/news-author-04.jpg')}