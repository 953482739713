import React, { useState } from "react";
import Slider from "react-slick";
import { GET_BOOTH_BY_LANGUAGE } from "../../graphql/query";
import { useQuery } from "react-apollo";
import history from "../../utils/History";
import SrcnLoader from "./SrcnLoader";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const FrenchFloor = () => {
  const { data } = useQuery(GET_BOOTH_BY_LANGUAGE, {
    fetchPolicy: "cache-and-network",
  });

  const [videoLoader, setVideoLoader] = useState(true);

  const onFloorLoad = () => {
    setVideoLoader(false);
  };

  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  const settings = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const navigateToBooth = (id) => {
    history.push(`/exhibition/floor/booth/${id}`);
  };

  console.log("Krunal", data);
  return (
    <div className="loadTansection flex exhibitionFloorPage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      <div className="exhibitionFloorSlider">
        {videoLoader && <SrcnLoader />}
        <Slider {...settings}>
          {data &&
            data?.country?.map((c) => (
              <div>
                <div className="exhibitionFloorImg w-screen">
                  <video
                    className="object-cover w-screen h-screen"
                    width="100%"
                    onLoadedData={onFloorLoad}
                    autoPlay
                    loop
                    muted
                    height="100%"
                    src={`${REACT_APP_CLOUDFRONT_URL}/floor/${code}/floor_${c?.country.toLowerCase()}.mp4`}
                    controls={false}
                  ></video>
                  <div
                    className={`boothContainer boothFloor_${c.booths.length}`}
                  >
                    {c &&
                      c?.booths?.map((booth) => {
                        return (
                          <span
                            key={booth.id}
                            onClick={() => navigateToBooth(booth.id)}
                            className={`hint boothPoint_${booth.order}`}
                            rel="Sessions"
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default FrenchFloor;
