import React, { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import { useTranslation } from "react-i18next";
import VideoPopUp from "./VideoPopUp";
import { INSERT_USER_REWARD_TYPE } from "../../graphql/mutation";
import { PANO_VIDEO, CONFLICT_ON_PANO_VIDEO } from "../../constants/constants";
import { useMutation } from "react-apollo";
import { useRecoilState } from "recoil";
import { pointsState } from "../../atoms/atoms";

const { REACT_APP_CLOUDFRONT_URL } = process.env;

const Tour = ({ onTourClose }) => {
  const [currentImage, setCurrentImage] = useState(
    `${REACT_APP_CLOUDFRONT_URL}/panos/1.jpg`
  );
  const [isVideo, setIsVideo] = useState(false);
  const [points] = useRecoilState(pointsState);
  const [src, setSrc] = useState("");
  const [next, setNext] = useState(1);
  const { t } = useTranslation();

  const { code } = JSON.parse(localStorage.getItem("language_settings"));
  const [SEND_USER_REWARD_TYPE] = useMutation(INSERT_USER_REWARD_TYPE);

  const onCloseVideoModal = () => {
    setIsVideo(false);
  };

  const onVideoEnded = async () => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          type: `${CONFLICT_ON_PANO_VIDEO}_${next}_${code}`,
          points: PANO_VIDEO + points,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const hanldeClickImage = (args) => {
    const nextFrame = args.name;
    if (args.name > 7 || args.name <= 0) {
      setNext(1);
    } else {
      setNext(nextFrame);
    }
  };

  useEffect(() => {
    setCurrentImage(`${REACT_APP_CLOUDFRONT_URL}/panos/${next}.jpg`);
    setSrc(`${REACT_APP_CLOUDFRONT_URL}/panos/videos/${code}/${next}.mp4`);
  }, [next]);

  const openVidePopUp = () => {
    setIsVideo(true);
  };

  const title = [
    `${t("Capsule")}`,
    `${t("Injectables")}`,
    `${t("Oinment")}`,
    `${t("Oral Liquid")}`,
    `${t("Packaging")}`,
    `${t("Tablets")}`,
    `${t("Warehouse")}`,
  ];

  return (
    <div className="fixed view360Model model_blk z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={onTourClose}></div>
                </div> */}
        {/* <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span> */}
        <div className="customPopContainer bg-white px-5 pt-4 pb-4 sm:pb-4">
          <div className="close" onClick={onTourClose}>
            <span></span>
            <span></span>
          </div>
          <div className="sm:flex flex-col sm:items-start">
            <div className="popup-header border-b border-solid w-full">
              <h3>{t("Cadila Manufacturing Plant")}</h3>
            </div>
            <div className="popup-body w-full">
              <Pannellum
                width="100%"
                height="100%"
                image={currentImage}
                title={t("Cadila Manufacturing Plant")}
                showFullscreenCtrl={false}
                pitch={0}
                yaw={0}
                hfov={100}
                autoLoad
                onLoad={() => {
                  console.log("panorama loaded");
                }}
              ></Pannellum>
              <div className="pannellumAction">
                <div
                  className="next arrow"
                  onClick={() => hanldeClickImage({ name: next + 1 })}
                ></div>
                <div className="playPannellum" onClick={openVidePopUp}>
                  <img src={require("../../images/video-player.svg")} alt="" />
                </div>
                <div
                  className="prev arrow"
                  onClick={() => hanldeClickImage({ name: next - 1 })}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVideo && (
        <VideoPopUp
          title={title[next - 1]}
          src={src}
          onCloseVideoModal={onCloseVideoModal}
          onVideoEnded={onVideoEnded}
          loading={false}
        />
      )}
    </div>
  );
};

export default Tour;
