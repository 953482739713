import { ApolloClient } from "apollo-client";
import { history } from "./history";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
const cache = new InMemoryCache();

const { REACT_APP_GRAPHQL_URL } = process.env;

const returnToken = () => {
  const token = localStorage.getItem("imicon_access_token");
  return token ? `Bearer ${token}` : null;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, statusCode, path }) => {
      switch (statusCode) {
        case 403:
          // messageBox.error("Resource you are trying to access is forbidden");
          break;
        default:
          break;
      }

      if (path) {
        if (path[0] !== "verify") {
          switch (statusCode) {
            case 401:
              history.push("/login");
              localStorage.removeItem("imicon_access_token");
              Client.resetStore();
              break;
            default:
          }
        }
      }

      return null;
    });
  }

  if (networkError) {
    console.log("networkError");
  }
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const access_token = localStorage.getItem("imicon_access_token");

  // Use the setContext method to set the HTTP headers.
  if (access_token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      http: {
        includeExtensions: true,
        includeQuery: true,
      },
    });
  }
  console.log("operation", operation);
  return forward(operation);
});

const httpLink = new createHttpLink({
  // uri : "https://imicon.herokuapp.com/v1/graphql"
  uri: `https://${REACT_APP_GRAPHQL_URL}/v1/graphql`,
});

const wsLink = new WebSocketLink({
  // uri: "wss://imicon.herokuapp.com/v1/graphql",
  uri: `wss://${REACT_APP_GRAPHQL_URL}/v1/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      if (localStorage.getItem("imicon_access_token")) {
        return {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              "imicon_access_token"
            )}`,
          },
        };
      } else {
        return {};
      }
    },
  },
});

const subscriptionMiddleware = {
  applyMiddleware(options, next) {
    options["authorization"] = returnToken();
    next();
  },
};

wsLink.subscriptionClient.on("connecting", () => {
  console.log("connecting");
});

wsLink.subscriptionClient.on("connected", () => {
  console.log("connected");
});

wsLink.subscriptionClient.on("reconnecting", () => {
  console.log("reconnecting");
});

wsLink.subscriptionClient.on("reconnected", () => {
  console.log("reconnected");
});

wsLink.subscriptionClient.on("disconnected", () => {
  console.log("disconnected");
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;
wsLink.subscriptionClient.use([subscriptionMiddleware]);

// The split function takes three parameters:

// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "all",
  },
};

let links = [errorLink, authLink, splitLink];

const link = ApolloLink.from(links);

const Client = new ApolloClient({
  link: link,
  cache,
  defaultOptions: defaultOptions,
});

Client.restartWebsocketConnection = () => {
  if (wsLink.subscriptionClient.client) {
    // Close socket connection which will also unregister subscriptions on the server-side.
    wsLink.subscriptionClient.close();

    // Reconnect to the server.
    wsLink.subscriptionClient.connect();

    // // Reregister all subscriptions.
    // Object.keys(wsLink.operations).forEach((id) => {
    //   wsLink.sendMessage(id, MessageTypes.GQL_START, wsLink.operations[id].options);
    // });
  }
};

export default Client;
