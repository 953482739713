import { atom } from "recoil";

export const pointsState = atom({
    key: "pointsState",
    default: 0,
});

export const accessTokenState = atom({
    key: "accessTokenState",
    default: "",
});

export const isCoinUIOpenState = atom({
    key: "isCoinUIOpenState",
    default: false,
});

export const earningState = atom({
    key: "earningState",
    default: 0,
});