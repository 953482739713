import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Empty from "./Empty";
import Loader from "./Loader";

const MyBag = ({
  onCloseMybag,
  onDeleteMyBagItem,
  loading,
  deleting,
  userId,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <div className="fixed modelMyBag model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50" onClick={onCloseMybag}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseMybag}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div class="popup-header border-b border-solid w-full">
                <h3>{t("My Bag")}</h3>
              </div>
              <div className="popup-body w-full">
                <div className="tabContainer">
                  <ul className="ul-bag">
                    {(!data && loading) || deleting ? (
                      <Loader />
                    ) : data && data?.user_resource.length ? (
                      data.user_resource.map(({ resource }, index) => (
                        <li key={index}>
                          <div className="flex">
                            <span className="icon-bag-fr">
                              <img
                                src={require("../../images/pdf.svg")}
                                alt="pdf"
                              />
                            </span>
                            <div className="text-bag-inside">
                              {resource.name}
                            </div>
                          </div>
                          <div className="icons-bag-right items-center">
                            <a
                              href={resource.url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <img
                                alt="view"
                                src={require("../../images/eye.svg")}
                                className="eye-icon"
                              />
                            </a>
                            <Link
                              onClick={() => onDeleteMyBagItem(resource.id)}
                              className="mr-2 ml-1"
                            >
                              <img
                                alt="delete"
                                src={require("../../images/delete.svg")}
                              />
                            </Link>
                          </div>
                        </li>
                      ))
                    ) : (
                          <Empty />
                        )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBag;
