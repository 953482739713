import React from "react";
import { useTranslation } from "react-i18next";

const CoinsPopUp = ({ points, onStartTour }) => {
  const { t } = useTranslation();

  return (
    <div className="coinPopup">
      <div className="coinOverlay"></div>
      <div className="coinModal">
        <div className="coinModalHeader flex justify-between">
          <h3>{t("Coin Balance")}</h3>
          <div className="coinImg">
            <img alt="" src={require("../../images/coin.png")} />
          </div>
        </div>

        <div className="coinModalBody">
          <div className="coinModalFooter flex flex-row items-center justify-center">
            <div className="earnViewText text-center flex flex-col">
              <h5>{t("Congratulations")}</h5>
              <span>
                {t("You have")} <strong>{points}</strong>{" "}
                {t("in your Coin balance")}
              </span>
            </div>
            <div className="earnViewBtn"></div>
          </div>
          <div className="coinCountCenter">
            <div className="coinCount">
              <div className="coinImgSec">
                <div className="coin coin-md">
                  <div className="coin__front">
                    <img alt="" src={require("../../images/coin.png")} />
                  </div>
                  <div className="coin__edge">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="coin__back">
                    <img alt="" src={require("../../images/coin.png")} />
                  </div>
                  <div className="coin__shadow"></div>
                </div>
              </div>
              <div className="coinCount">{points}</div>
            </div>
          </div>
          <div className="coinModalFooter flex flex-row items-center justify-center">
            <div className="earnViewBtn">
              <a href="#" onClick={onStartTour}>
                {t("Start the tour")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinsPopUp;
