import React from "react";
import { useTranslation } from "react-i18next";
import Empty from "./Empty";
import Loader from "./Loader";
import CustIcon from "./Svgs";

const Agenda = ({
  onCloseAgendaTab,
  src,
  handleAddToBag,
  loading,
  loadingdata,
}) => {
  const { t } = useTranslation();

  return (
    <div className="fixed agendaPop model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onCloseAgendaTab}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white pt-4 px-5 12 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseAgendaTab}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col">
              <div className="popup-header">
                <div className="flex justify-between items-center mb-2 w-full">
                  <h3>{t("Agenda")}</h3>
                  <button
                    disabled={!src}
                    className="btn-submit -mt-3"
                    onClick={handleAddToBag}
                  >
                    {loading ? `${t("Added")}` : `${t("Add to Cart")}`}
                    <CustIcon type="coin" size="xs" className="ml-3" />
                  </button>
                  <h3 className="invisible">{t("Agenda")}</h3>
                </div>
              </div>
              {loadingdata ? (
                <Loader />
              ) : (
                <div className="popup-body">
                  {src ? (
                    <iframe
                      src={src}
                      title="agenda"
                      className="border-none mb-3 w-full"
                    />
                  ) : (
                    <Empty />
                  )}
                  {/* <div className="w-full flex justify-center">
                      <button
                        disabled={!src}
                        className="btn-submit"
                        onClick={handleAddToBag}
                      >
                        {loading ? `${t("Added")}` : `${t("Add to Cart")}`}
                        <CustIcon type="coin" size="xs" className="ml-3" />
                      </button>
                    </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
