import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const ConferenceChat = ({
  onCloseChatTab,
  onChange,
  onSendChatMessage,
  message,
  sending,
  loading,
  data,
  handleKeyDownSession,
}) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      scrollToBottom();
    }
  }, [data]);

  return (
    <div className="chat bottomModel fixed z-10" id="seminarChat">
      <div className=" card card-poll card-chat d-flex flex-column rounded-t-lg bg-white card-absolute-bottom">
        <div className="card-header flex items-center justify-between py-3 px-3 mb-0">
          <h3 className="card-title">{t("Chat")}</h3>
          <div onClick={onCloseChatTab} className="close-div">
            <img
              alt="close"
              src={require("../../images/close.svg")}
              width="12"
              height="12"
            />
          </div>
        </div>
        <div className="card-body flex-grow-1">
          <div className="chatSection_blk">
            <ul className="chatList">
              {!data && loading ? (
                <Loader />
              ) : (
                data &&
                data.conference[0].conference_chats.map((chat, index) => (
                  <li
                    key={index}
                    className={
                      chat.by === localStorage.getItem("imicon_user_id")
                        ? "me"
                        : ""
                    }
                  >
                    <div className="user">
                      <img src={chat.user.photo} alt="profile" />
                    </div>
                    <div className="content" key={index}>
                      <div className="userName">
                        {chat.by === localStorage.getItem("imicon_user_id")
                          ? "me"
                          : `${chat.user.firstname} ${chat.user.lastname}`}
                      </div>
                      <div className="message">{chat.message}</div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <div ref={chatEndRef} />
          </div>
        </div>
        <div className="card-footer card-footer-que border-t">
          <div className="flex justify-between items-center p-3">
            <img alt="add" src={require("../../images/add.svg")} />
            <input
              type="text"
              onChange={onChange}
              onKeyDown={handleKeyDownSession}
              value={message}
              className="msg-input w-full px-3"
              placeholder={t("Type your message")}
            />
            <button onClick={onSendChatMessage} className="btn-submit">
              {sending ? `${t("Sending")}` : `${t("Send")}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceChat;
