import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export const Games = () => {
  const { t } = useTranslation();

  return (
    <section className="loadTansection gameSec flex justify-center  bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      <div className="mx-auto px-12 py-8">
        <div className="gameList">
          <ul className="gameLisinUl">
            <li>
              <div className="gameCard">
                <Link
                  to={"/exhibition/games/play/casino"}
                  className="absolute h-full left-0 top-0 w-full z-1"
                ></Link>
                <div className="gameCardImg">
                  <img src={require("../../images/game1.png")} alt="" />
                </div>
                <div className="gameCont">
                  <h3>{t("American Roulette Royale")}</h3>
                  <p className="flex items-center">
                    {t("Play and Earn")}
                    <img
                      alt=""
                      src={require("../../images/coin.png")}
                      className="h-6 mx-2"
                    />{" "}
                    {t("Coins")}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="gameCard">
                <Link
                  to={"/exhibition/games/play/wof"}
                  className="absolute h-full left-0 top-0 w-full z-1"
                ></Link>
                <div className="gameCardImg">
                  <img src={require("../../images/game2.png")} alt="" />
                </div>
                <div className="gameCont">
                  <h3>{t("Wheel of fortune")}</h3>
                  <p className="flex items-center">
                    {t("Play and Earn")}
                    <img
                      alt=""
                      src={require("../../images/coin.png")}
                      className="h-6 mx-2"
                    />
                    {t("Coins")}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="gameCard">
                <Link
                  to={"/exhibition/games/play/3cards"}
                  className="absolute h-full left-0 top-0 w-full z-1"
                ></Link>
                <div className="gameCardImg">
                  <img src={require("../../images/game3.png")} alt="" />
                </div>
                <div className="gameCont">
                  <h3>{t("High Low")}</h3>
                  <p className="flex items-center">
                    {t("Play and Earn")}
                    <img
                      alt=""
                      src={require("../../images/coin.png")}
                      className="h-6 mx-2"
                    />
                    {t("Coins")}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="gameCard">
                <Link
                  to={"/exhibition/games/play/bubble"}
                  className="absolute h-full left-0 top-0 w-full z-1"
                ></Link>
                <div className="gameCardImg">
                  <img src={require("../../images/game4.png")} alt="" />
                </div>
                <div className="gameCont">
                  <h3>{t("Bubble Shooter")}</h3>
                  <p className="flex items-center">
                    {t("Play and Earn")}
                    <img
                      alt=""
                      src={require("../../images/coin.png")}
                      className="h-6 mx-2"
                    />
                    {t("Coins")}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="gameCard">
                <Link
                  to={"/exhibition/games/play/fishjump"}
                  className="absolute h-full left-0 top-0 w-full z-1"
                ></Link>
                <div className="gameCardImg">
                  <img src={require("../../images/game5.png")} alt="" />
                </div>
                <div className="gameCont">
                  <h3>{t("Fishing Frenzy")}</h3>
                  <p className="flex items-center">
                    {t("Play and Earn")}
                    <img
                      alt=""
                      src={require("../../images/coin.png")}
                      className="h-6 mx-2"
                    />
                    {t("Coins")}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
