import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const { REACT_APP_CLOUDFRONT_URL } = process.env;

const Certificate = ({
  onCloseCertificateTab,
  loading,
  onOpenMyProfileModal,
}) => {
  const { t } = useTranslation();
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  return (
    <div className="fixed model_blk modalCertificate z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onCloseCertificateTab}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="dialogBox inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl md:max-w-3xl lg:max-w-2xl xl:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white pt-4 px-5 12 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseCertificateTab}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div className="popup-header">
                <h3>{t("Certificate")}</h3>
              </div>
              <div className="popup-body">
                <div className="flex items-center justify-between w-full mb-3">
                  <span className="pr-3">{t("Your certificate request")}</span>
                  <button
                    onClick={onOpenMyProfileModal}
                    className="btn-submit whitespace-no-wrap"
                  >
                    {t("My Profile")}
                  </button>
                </div>
                <div className="certificateImg text-center">
                  <img
                    src={`${REACT_APP_CLOUDFRONT_URL}/certificates/cert_${code}.jpg`}
                    alt="certificates"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
