import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import { GET_COUNTRIES } from "../../graphql/query";
import countries from "../../utils/countries.json";
import Loader from "./Loader";

const Countries = () => {
  const { loading, data, errors } = useQuery(GET_COUNTRIES);

  return (
    <div className="loadTansection flex languagePage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      <main className="flex-grow flex items-center justify-center w-1/1 h-1/1">
        <div className="flex selectLang text-center md:px-0 px-4 md:text-base text-xs">
          {!data && loading ? (
            <Loader />
          ) : (
            data &&
            data.country.map((u, index) => (
              <Link
                to={`/exhibition/floor/${u.id}`}
                className="col-span-1 md:px-3 focus:shadow-none"
                key={index}
              >
                <img
                  alt=""
                  src={require(`../../images/countries/${u.country.toLowerCase()}.png`)}
                />
                <span className="text-white">
                  {countries.find((country) => country.id === u.country).name}
                </span>
              </Link>
            ))
          )}
        </div>
      </main>
    </div>
  );
};

export default Countries;

// {require('../images/old/news-author-04.jpg')}
