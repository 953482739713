import { gql } from "apollo-boost";

export const GET_CONFERENCE = gql`
  query conference($type: String!) {
    conference(where: { type: { _eq: $type } }) {
      id
      resources {
        points
        id
        url
      }
      playlists {
        playlist_videos(where: { active: { _eq: true } }) {
          id
          is_live
          description
          title
          url
          active
          points
          banners
          give_at
        }
      }
    }
  }
`;

export const GET_CONFERENCE_CHAT = gql`
  query conference($type: String!) {
    conference(where: { type: { _eq: $type } }) {
      conference_chats {
        id
        by
        message
        user {
          id
          language_id
          firstname
          lastname
          email
          photo
        }
      }
    }
  }
`;

export const GET_CONFERENCE_QA = gql`
  query conference_by_pk_questions($id: uuid!) {
    conference_by_pk(id: $id) {
      qas {
        question
        answer
      }
    }
  }
`;

export const GET_GLOBAL_CHAT = gql`
  query global_chat {
    global_chat(order_by: { timestamp: asc }) {
      id
      by
      message
      user {
        id
        language_id
        firstname
        lastname
        email
        photo
      }
    }
  }
`;

export const GET_BOOTH_DETAILS_BY_ID = gql`
  query booth_by_pk($id: uuid!) {
    booth_by_pk(id: $id) {
      country {
        country
      }
      name
      order
      position
      resources {
        active
        name
        url
        id
        points
        resource_type {
          name
          id
        }
      }
    }
  }`
  ;


export const GET_BOOTH_DETAILS_BY_TYPE = gql`
  query GET_BOOTH_DETAILS_BY_TYPE($type: String!,$resource_type:String!) {
    booth(where: { type: { _eq: $type } }) {
      name
      resources(order_by: {order: asc}, where: {resource_type: {name: {_eq: $resource_type}}}) {
        name
        order
        points
        url
        active
        id
        resource_type {
          name
          id
        }
      }
    }
  }`
;

export const GET_LOBBY_AGENDA_BY_TYPE = gql`
  query GET_BOOTH_DETAILS_BY_TYPE($type: String!,$resource_type:String!) {
    booth(where: { type: { _eq: $type } }) {
      name
      resources(order_by: {order: asc}, where: {resource_type: {name: {_eq: $resource_type}}}) {
        name
        points
        url
        id
        resource_type {
          name
          id
        }
      }
    }
  }`
  ;

export const GET_BOOTH_POLL_BY_ID = gql`
  query booth_by_pk($id: uuid!) {
    booth_by_pk(id: $id) {
      polls {
        id
        options
        question
        answer
      }
    }
  }
`;

export const GET_ATTENDEES = gql`
  query users($id: String!, $last_login: Boolean!) {
    user(where: { id: { _neq: $id }, last_login: { _eq: $last_login } }) {
      id
      firstname
      lastname
      photo
      user_chats_to(
        distinct_on: conversation_id
        where: { from: { _eq: $id } }
      ) {
        conversation_id
      }
      user_chats_from(
        distinct_on: conversation_id
        where: { to: { _eq: $id } }
      ) {
        conversation_id
      }
    }
  }
`;

export const GET_MY_CONTACTS = gql`
  query conversations {
    conversations {
      id
      user_chats(distinct_on: conversation_id) {
        from
        to
        message_from {
          id
          language_id
          firstname
          lastname
          email
          photo
        }
        message_to {
          id
          language_id
          firstname
          lastname
          email
          photo
        }
      }
    }
  }
`;

export const GET_CONVERSTION_BY_ID = gql`
  query conversations_by_pk($id: uuid!) {
    conversations_by_pk(id: $id) {
      user_chats(order_by: { timestamp: asc }) {
        from
        message_from {
          id
          language_id
          firstname
          lastname
          email
          photo
        }
        message_to {
          id
          language_id
          firstname
          lastname
          email
          photo
        }
        id
        message
        to
        timestamp
        conversation_id
      }
    }
  }
`;

export const GET_MY_BAG = gql`
  query user_resource {
    user_resource {
      user_id
      resource {
        id
        name
        url
        points
        resource_type {
          name
          id
        }
      }
    }
  }
`;

export const GET_CONFERENCE_POLL = gql`
  query conference_by_pk($id: uuid!) {
    conference_by_pk(id: $id) {
      playlists {
        playlist_videos(where: { active: { _eq: true } }) {
          polls {
            id
            options
            question
            answer
          }
        }
      }
    }
  }
`;

export const GET_POLL_ANSWER_BY_QUESTION_ID = gql`
  query poll_answer($question_id: uuid!) {
    poll_answer(where: { question_id: { _eq: $question_id } }) {
      answer
      question_id
    }
  }
`;

export const GET_USER_POINTS = gql`
  query user_reward {
    user_reward {
      points
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query user_by_pk($id: String!) {
    user_by_pk(id: $id) {
      department
      email
      firstname
      lastname
      phone
      photo
      skill
      dob
      city
      hospital_name
      hospital_address
      code
    }
  }
`;

export const GET_COUNTRIES = gql`
  query country {
    country {
      country
      id
    }
  }
`;

export const GET_FEED_BACK = gql`
  query feedback {
    feedback {
      content_rating
      overall_rating
      platform_rating
      will_attend
      comments
    }
  }
`;

export const GET_LOBBY_VIDEO = gql`
  query resource_by_pk($id: uuid!) {
    resource_by_pk(id: $id) {
      name
      points
      url
      id
    }
  }
`;

export const GET_BOOTH_LIST_BY_COUNTRY = gql`
  query country_by_pk($id: uuid!) {
    country_by_pk(id: $id) {
      country
      booths(order_by: { order: asc }) {
        order
        id
      }
    }
  }
`;

export const GET_BOOTH_BY_LANGUAGE = gql`
  query country {
    country {
      country
      booths {
        order
        id
      }
      id
    }
  }
`;


export const GET_SETTINGS = gql`
  query settings($type:String!) {
    settings(where: {type: {_eq:$type }}) {
      status
    }
  }
`

