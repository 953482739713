import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
// import "react-tabs/style/react-tabs.css";
import Loader from "./Loader";
import { useDropzone } from "react-dropzone";
import { getSignedURL } from "../../rest/api";
import axios from "axios";
import { STORE_USER_PROFILE } from "../../graphql/mutation";
import { GET_USER_DETAILS } from "../../graphql/query";
import { useMutation } from "react-apollo";
import produce from "immer";
import CustIcon from "./Svgs";
import ReactPhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { REACT_APP_CLOUDFRONT_URL } = process.env;

const MyProfile = ({
  onCloseMyProfile,
  loading,
  data,
  sending,
  onSubmitForm,
  updated,
}) => {
  const user_id = localStorage.getItem("imicon_user_id");
  const [phone, setPhone] = useState(undefined);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [dob, setDob] = useState(undefined);
  const onDrop = useCallback(async (acceptedFiles) => {
    var file = acceptedFiles[0];
    const signedURL = await getSignedURL(file?.type?.split("/").pop());

    var options = {
      headers: {
        "Content-Type": file?.type,
      },
    };

    await axios.put(signedURL.data, file, options);

    SEND_USER_PROFILE_MUTATION({
      variables: {
        id: user_id,
        photo: `${REACT_APP_CLOUDFRONT_URL}/profile/${user_id.toLowerCase()}.${file?.type?.split("/")?.pop()}`,
      },
      update: (store, { data }) => {
        const { user_by_pk } = store.readQuery({
          query: GET_USER_DETAILS,
          variables: { id: user_id },
        });

        const updatedUser = produce(user_by_pk, (draft) => {
          draft.photo = `${data?.update_user_by_pk?.photo}?v=${new Date().getTime()}`;
        });

        store.writeQuery({
          query: GET_USER_DETAILS,
          variables: { id: user_id },
          data: {
            user_by_pk: {
              ...updatedUser,
            },
          },
        });
      },
    });
  }, []);

  const [SEND_USER_PROFILE_MUTATION] = useMutation(STORE_USER_PROFILE);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, errors, formState } = useForm();

  const { code: langCode } = JSON.parse(
    localStorage.getItem("language_settings")
  );

  useEffect(() => {
    if (data) {
      setValue("firstname", data?.user_by_pk?.firstname);
      setValue("lastname", data.user_by_pk.lastname);
      setEmail(data?.user_by_pk?.email);
      setSpeciality(data?.user_by_pk?.skill ? data?.user_by_pk?.skill : "");
      setPhone(data?.user_by_pk?.phone ? data?.user_by_pk?.phone : "");
      setCode(data?.user_by_pk?.code ? data?.user_by_pk?.code : "");
      setValue("department", data.user_by_pk.department);
      setValue("hospital_name", data.user_by_pk.hospital_name);
      setValue("hospital_address", data.user_by_pk.hospital_address);
      setValue("city", data.user_by_pk?.city);
      setDob(
        data?.user_by_pk?.dob ? new Date(data?.user_by_pk?.dob) : undefined
      );
    }
  }, [data]);

  const handleOnChange = (value, data) => {
    let withoutCodeValue = value.replace("+" + data.dialCode, "");
    setCode("+" + data.dialCode);
    setPhone(withoutCodeValue);
  };
  return (
    <div className="fixed modelMyProfile model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={updated && onCloseMyProfile}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            {updated && (
              <div className="close" onClick={onCloseMyProfile}>
                <span></span>
                <span></span>
              </div>
            )}
            <div className="sm:flex flex-col sm:items-start">
              <div className="popup-header border-b border-solid w-full">
                <h3>{t("My Profile")}</h3>
              </div>
              <div className="popup-body profileSec w-full">
                {!data && loading ? (
                  <Loader />
                ) : (
                  data && (
                    <form
                      onSubmit={handleSubmit((values) => {
                        values = {
                          ...values,
                          phone,
                          code,
                          dob,
                          email,
                          skill: speciality,
                        };
                        if (!!phone) {
                          onSubmitForm(values);
                        }
                      })}
                    >
                      <div className="w-full py-4">
                        <h3 className="text-center mb-3 font-bold">
                          {localStorage.getItem("imicon_user_id")}
                        </h3>
                        <div className="upload">
                          <div {...getRootProps()} className="uploadImg">
                            <img src={data?.user_by_pk?.photo} alt="profile" />
                            <span className="edit">
                              <input {...getInputProps()} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap myProfile">
                        <div className="w-1/3 px-3">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="firstname"
                            label={t("First Name")}
                            type="text"
                            ref={register({ required: false })}
                            placeholder={t("Type your First Name")}
                            disabled={
                              data?.user_by_pk?.firstname ? true : false
                            }
                          />
                        </div>
                        <div className="w-1/3 px-3">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="lastname"
                            label={t("Last Name")}
                            ref={register({ required: false })}
                            type="text"
                            placeholder={t("Type your Last Name")}
                            disabled={data?.user_by_pk?.lastname ? true : false}
                          />
                        </div>
                        <div className="w-1/3 px-3">
                          {langCode === "en" && (
                            <span className="reqStar">*</span>
                          )}
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            type="text"
                            label={t("Email")}
                            ref={register({
                              required: langCode === "en" ? true : false,
                              pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            name="email"
                            placeholder={t("example@email.com")}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {errors?.email && (
                            <span className="reqErrorTxt">
                              {`${t("Email address is required")}`}
                            </span>
                          )}
                        </div>
                        <div className="w-1/3 px-3">
                          <span className="reqStar">*</span>
                          <ReactPhoneInput
                            inputExtraProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            defaultCountry={"in"}
                            value={code + phone}
                            onChange={handleOnChange}
                          />
                          {formState?.isSubmitted && !phone && (
                            <span className="reqErrorTxt">
                              {`${t("Phone number is required")}`}
                            </span>
                          )}
                        </div>
                        <div className="w-1/3 px-3">
                          <span className="reqStar">*</span>
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="skill"
                            label={t("Speciality")}
                            ref={register({ required: true })}
                            type="text"
                            value={speciality}
                            onChange={(e) => setSpeciality(e.target.value)}
                            placeholder={t("Speciality")}
                            disabled={data?.user_by_pk?.skill ? true : false}
                          />
                          {errors.skill && (
                            <span className="reqErrorTxt">
                              {`${t("Speciality is Required")}`}
                            </span>
                          )}
                        </div>
                        <div className="w-1/3 px-3">
                          {langCode === "en" && (
                            <span className="reqStar">*</span>
                          )}
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="city"
                            label={t("City")}
                            ref={register({
                              required: langCode === "en" ? true : false,
                            })}
                            type="text"
                            placeholder={t("City")}
                          />
                          {errors.city && (
                            <span className="reqErrorTxt">
                              {`${t("City is Required")}`}
                            </span>
                          )}
                        </div>
                        <div className="w-1/3 px-3 flex flex-col">
                          <DatePicker
                            name="dob"
                            placeholderText={t("Date of Birth")}
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            selected={dob}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={40}
                            popperPlacement="top-start"
                            onChange={(date) => setDob(date)}
                          />
                        </div>
                        <div className="w-1/3 px-3">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="department"
                            label={t("Department")}
                            type="text"
                            placeholder={t("Department")}
                          />
                        </div>

                        <div className="w-1/3 px-3">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="hospital_name"
                            label={t("Hospital Name")}
                            ref={register({ required: false })}
                            type="text"
                            placeholder={t("Hospital Name")}
                          />
                        </div>
                        <div className="w-full px-3">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="hospital_address"
                            label={t("Hospital Address")}
                            ref={register({ required: false })}
                            type="text"
                            placeholder={t("Hospital Address")}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="btn-submit flex mx-auto mt-3"
                        >
                          {sending ? `Updating...` : "Update"}{" "}
                          <CustIcon type="coin" size="xs" />
                        </button>
                      </div>
                    </form>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
