import { gql } from "apollo-boost";

export const ACCEPT_REQUEST = gql`
  mutation acceptRequest($requestId: String!) {
    acceptRequest(requestId: $requestId) {
      message
      status
    }
  }
`;

export const STORE_CONF_CHAT = gql`
  mutation insert_conference_chat_one(
    $conference_id: uuid!
    $message: String!
  ) {
    insert_conference_chat_one(
      object: { conference_id: $conference_id, message: $message }
    ) {
      id
      message
      by
      user {
        id
        language_id
        firstname
        lastname
        email
        photo
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String!) {
    login(username: $username) {
      access_token
      id
      language_id
      org_id
      last_login
      language {
        code
      }
    }
  }
`;

export const STORE_CONF_QUESTION = gql`
  mutation insert_qa_one($question: String!, $conference_id: uuid!) {
    insert_qa_one(
      object: { question: $question, conference_id: $conference_id }
    ) {
      question
      answer
    }
  }
`;

export const STORE_GLOBAL_CHAT = gql`
  mutation insert_global_chat_one($message: String!) {
    insert_global_chat_one(object: { message: $message }) {
      id
      by
      message
      user {
        id
        language_id
        firstname
        lastname
        email
        photo
      }
    }
  }
`;

export const STORE_PERSONAL_CHAT = gql`
  mutation insert_user_chat_one(
    $conversation_id: uuid!
    $message: String!
    $to: String!
  ) {
    insert_user_chat_one(
      object: { conversation_id: $conversation_id, message: $message, to: $to }
    ) {
      from
      message_from {
        id
        language_id
        firstname
        lastname
        email
        photo
      }
      message_to {
        id
        language_id
        firstname
        lastname
        email
        photo
      }
      id
      message
      timestamp
      to
      conversation_id
    }
  }
`;

export const STORE_USER_FORM = gql`
  mutation update_user_by_pk(
    $id: String!
    $firstname: String!
    $lastname: String!
    $last_login: Boolean!
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        firstname: $firstname
        lastname: $lastname
        last_login: $last_login
      }
    ) {
      last_login
    }
  }
`;
export const UPDATE_USER_FORM = gql`
  mutation update_user_by_pk(
    $id: String!
    $email: String
    $department: String
    $phone: String
    $skill: String
    $dob: date
    $city: String
    $hospital_name: String
    $hospital_address: String
    $last_login: Boolean!
    $code: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: $email
        department: $department
        phone: $phone
        skill: $skill
        dob: $dob
        hospital_name: $hospital_name
        hospital_address: $hospital_address
        city: $city
        last_login: $last_login
        code: $code
      }
    ) {
      last_login
      email
      department
      phone
      skill
      hospital_name
      hospital_address
      city
      dob
      code
    }
  }
`;

export const STORE_USER_PROFILE = gql`
  mutation update_user_by_pk($id: String!, $photo: String!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { photo: $photo }) {
      photo
    }
  }
`;

export const ADD_TO_RESOURCE = gql`
  mutation insert_user_resource_one($resource_id: uuid!) {
    insert_user_resource_one(
      object: { resource_id: $resource_id }
      on_conflict: {
        constraint: user_resource_pkey
        update_columns: resource_id
      }
    ) {
      resource_id
      user_id
    }
  }
`;

export const DELETE_FROM_BY_BAG = gql`
  mutation delete_user_resource_by_pk($resource_id: uuid!, $user_id: String!) {
    delete_user_resource_by_pk(resource_id: $resource_id, user_id: $user_id) {
      resource_id
      user_id
    }
  }
`;

export const START_USER_CONVERSTION = gql`
  mutation insert_user_chat_one($to: String!, $message: String!) {
    insert_user_chat_one(
      object: { to: $to, message: $message, conversation: { data: {} } }
    ) {
      conversation_id
      to
      message
    }
  }
`;

export const INSERT_POLL_ANSWER = gql`
  mutation insert_poll_answer_one($answer: String!, $question_id: uuid!) {
    insert_poll_answer_one(
      object: { answer: $answer, question_id: $question_id }
    ) {
      question_id
      answer
    }
  }
`;

export const INSERT_USER_REWARD_VIDEO = gql`
  mutation insert_user_reward_one($points: float8!, $video_id: uuid!) {
    insert_user_reward_one(
      object: {
        points: $points
        point_tracking_videos: { data: { video_id: $video_id } }
      }
      on_conflict: { constraint: user_reward_pkey, update_columns: points }
    ) {
      id
      points
    }
  }
`;

export const INSERT_USER_REWARD_RESOURCE = gql`
  mutation insert_user_reward_one($points: float8!, $resource_id: uuid!) {
    insert_user_reward_one(
      object: {
        points: $points
        point_tracking_resources: { data: { resource_id: $resource_id } }
      }
      on_conflict: { constraint: user_reward_pkey, update_columns: points }
    ) {
      id
      points
    }
  }
`;
export const INSERT_USER_REWARD_TYPE = gql`
  mutation insert_user_reward_one($points: float8!, $type: String!) {
    insert_user_reward_one(
      object: {
        points: $points
        point_tracking_onces: { data: { type: $type } }
      }
      on_conflict: { constraint: user_reward_pkey, update_columns: points }
    ) {
      id
      points
    }
  }
`;

export const INSERT_USER_REWARD = gql`
  mutation insert_user_reward_one($points: float8!) {
    insert_user_reward_one(
      object: { points: $points }
      on_conflict: { constraint: user_reward_pkey, update_columns: points }
    ) {
      id
      points
    }
  }
`;

export const INSERT_USER_FEED_BACK = gql`
  mutation insert_feedback_one(
    $comments: String!
    $content_rating: numeric!
    $overall_rating: numeric!
    $platform_rating: numeric!
    $will_attend: Boolean!
  ) {
    insert_feedback_one(
      object: {
        comments: $comments
        content_rating: $content_rating
        overall_rating: $overall_rating
        platform_rating: $platform_rating
        will_attend: $will_attend
      }
    ) {
      id
      content_rating
      overall_rating
      platform_rating
      will_attend
      comments
    }
  }
`;

export const INSERT_USER_CERTIFICATES = gql`
  mutation insert_certificates_one {
    insert_certificates_one(object: {}) {
      id
    }
  }
`;

export const INSERT_USER_REDEEM_REQUEST = gql`
  mutation insert_requests {
    insert_requests(objects: {}) {
      affected_rows
    }
  }
`;

export const INSERT_USER_VISITS = gql`
  mutation insert_analytics_one(
    $booth_id: uuid
    $conference_id: uuid
    $type: String
  ) {
    insert_analytics_one(
      object: {
        booth_id: $booth_id
        conference_id: $conference_id
        type: $type
      }
    ) {
      id
      booth_id
      conference_id
      type
      user_id
    }
  }
`;
