import { gql } from "apollo-boost";

export const SUBSCRIBE_ON_REWARD = gql`
 subscription user_reward {
    user_reward {
      points
    }
  }
`;

export const SUBSCRIBE_ON_PLAYLIST_VIDEO = gql`
  subscription playlist_video($active:Boolean!,$type:String!) {
    playlist_video(where: {active: {_eq: $active}, playlist: {conference: {type: {_eq: $type}}}}) {
      show_marquee
      title
      description
      id
    }
  }
`;
export const SUBSCRIBE_ON_PLAYLIST_QA = gql`
subscription conference_by_pk($id: uuid!) {
  conference_by_pk(id: $id) {
    playlists {
      playlist_videos(where: {active: {_eq: true}}) {
        polls {
          id
          options
          question
          answer
        }
      }
    }
  }
}
`;

