import React from "react";

const CustIcon = (props) => {
  const addClassName = props.className === undefined ? "" : props.className;
  const coinSize = props.size === undefined ? "" : props.size;
  // const addClassName = props.className === undefined ? "" : props.className;
  switch (props.type) {
    case "info":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23.625 23.625"
          >
            <path d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813 S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189 c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759 l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717 c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828 c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53 c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68 c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728 c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z  M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193 c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497 c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z" />
          </svg>
        </i>
      );
    case "coin":
      return (
        <div className={`coin coin-${coinSize} ${addClassName}`}>
          <div className="coin__front">
            <img alt="" src={require("../../images/coin.png")} />
          </div>
          <div className="coin__edge">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="coin__back">
            <img alt="" src={require("../../images/coin.png")} />
          </div>
          <div className="coin__shadow"></div>
        </div>
      );
    case "brochure":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="m444.958 337.623h-223.949c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h223.949c4.144 0 7.503-3.359 7.503-7.503-.001-4.144-3.359-7.503-7.503-7.503z" />
            <path d="m444.958 378.638h-223.949c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h223.949c4.144 0 7.503-3.359 7.503-7.503-.001-4.144-3.359-7.503-7.503-7.503z" />
            <path d="m385.687 419.653h-164.678c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h164.678c4.144 0 7.503-3.359 7.503-7.503s-3.359-7.503-7.503-7.503z" />
            <path d="m491.978 122.663-61.435-61.435c-1.408-1.407-3.316-2.198-5.306-2.198h-84.842v-32.68c0-14.53-11.821-26.35-26.351-26.35h-269.868c-14.53 0-26.351 11.821-26.351 26.35v400.27c0 14.53 11.821 26.35 26.351 26.35h99.783c4.144 0 7.503-3.359 7.503-7.503s-3.359-7.503-7.503-7.503h-99.783c-6.256 0-11.345-5.089-11.345-11.345v-400.269c0-6.256 5.09-11.345 11.345-11.345h248.232l-97.996 37.616c-12.109 4.648-20.794 15.779-22.473 28.591-.501 3.14-.275 4.979-.333 8.535h-107.601c-7.303 0-13.246 5.942-13.246 13.246v67.21c0 7.303 5.942 13.246 13.246 13.246h107.602v20.148h-107.602c-7.303 0-13.246 5.942-13.246 13.246v67.21c0 7.303 5.942 13.246 13.246 13.246h107.602v22.653h-113.345c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h113.345v23.175h-113.345c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h113.345v112.513c0 14.53 11.821 26.351 26.35 26.351h269.867c14.53 0 26.35-11.821 26.35-26.351v-357.684c.001-1.99-.789-3.898-2.196-5.305zm-59.239-38.017 35.82 35.82h-24.475c-6.255 0-11.345-5.09-11.345-11.345zm-213.148-25.616 103.364-39.677c1.519 1.93 2.434 4.357 2.434 6.998v32.679zm-153.827 109.413v-63.69h105.842v63.69zm0 113.849v-63.69h105.842v63.69zm402.06 214.703h-269.867c-6.256 0-11.345-5.09-11.345-11.345v-400.27c0-6.194 5.036-11.345 11.345-11.345h219.777v35.085c0 14.53 11.821 26.351 26.35 26.351h35.085v350.178c0 6.256-5.089 11.346-11.345 11.346z" />
            <path d="m436.466 158.832h-148.233c-4.144 0-7.503 3.359-7.503 7.503s3.359 7.503 7.503 7.503h148.234c1.125 0 2.042.916 2.042 2.041v101.428l-38.102-45.909c-8.44-10.168-24.025-10.148-32.448 0l-16.359 19.71-34.942-42.101c-8.441-10.168-24.025-10.147-32.448 0l-56.935 68.601v-101.73c0-1.125.916-2.041 2.041-2.041h23.842c4.144 0 7.503-3.359 7.503-7.503s-3.359-7.503-7.503-7.503h-23.842c-9.399 0-17.047 7.647-17.047 17.047v107.489c0 9.4 7.647 17.047 17.047 17.047h207.151c9.4 0 17.047-7.647 17.047-17.047v-107.488c0-9.4-7.648-17.047-17.048-17.047zm-56.961 82.15c2.431-2.931 6.921-2.932 9.354 0l36.873 44.428h-83.099zm-83.749-22.391c2.431-2.931 6.921-2.932 9.354 0l36.739 44.266-18.718 22.553h-82.831z" />
          </svg>
        </i>
      );
    case "rating":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 60"
          >
            <path d="m47 10h-9.1807c-.9693425-5.77223655-5.9662376-9.99996284-11.8193-9.99996284s-10.8499575 4.22772629-11.8193 9.99996284h-9.1807c-2.76007596.0032511-4.99674886 2.239924-5 5v40c.00325114 2.760076 2.23992404 4.9967489 5 5h42c2.760076-.0032511 4.9967489-2.239924 5-5v-40c-.0032511-2.760076-2.239924-4.9967489-5-5zm-21-8c3.6610744-.00559156 7.0316138 1.99289473 8.7831738 5.20778734 1.75156 3.21489256 1.6032125 7.13056326-.3865738 10.20371266-1.2131529-1.3444304-2.7711385-2.3311788-4.5051-2.8533 1.9135519-1.6305698 2.6076417-4.2812124 1.7388273-6.64036481s-3.1162791-3.9264971-5.6303273-3.9264971-4.7615129 1.56734469-5.6303273 3.9264971-.1747246 5.00979501 1.7388273 6.64036481c-1.7339615.5221212-3.2919471 1.5088696-4.5051 2.8533-1.9897863-3.0731494-2.1381338-6.9888201-.3865738-10.20371266 1.75156-3.21489261 5.1220994-5.2133789 8.7831738-5.20778734zm-4 8c0-2.209139 1.790861-4 4-4s4 1.790861 4 4-1.790861 4-4 4c-2.2081794-.0023149-3.9976851-1.7918206-4-4zm11.1209 9.0092c-1.8755624 1.9129296-4.4419013 2.9907998-7.1209 2.9907998s-5.2453376-1.0778702-7.1209-2.9907998c1.7751741-2.0577036 4.4080743-3.1703331 7.1209-3.0092 2.7128257-.1611331 5.3457259.9514964 7.1209 3.0092zm16.8791 35.9908c-.0018187 1.6561003-1.3438997 2.9981813-3 3h-42c-1.65610033-.0018187-2.99818129-1.3438997-3-3v-40c.00181871-1.6561003 1.34389967-2.9981813 3-3h9c-.0024363 2.7757618.9620494 5.465693 2.7277 7.6075l.0131.0176c2.2766919 2.769795 5.6738004 4.3749037 9.2592 4.3749037s6.9825081-1.6051087 9.2592-4.3749037l.0131-.0176c1.7656506-2.141807 2.7301363-4.8317382 2.7277-7.6075h9c1.6561003.0018187 2.9981813 1.3438997 3 3z" />
            <path d="m22.4316 33.7188-.5087 3.12c-.0616038.3779282.0980135.7578514.4110482.9783826.3130347.2205313.7245162.2429439 1.0596518.0577174l2.6064-1.4403 2.6064 1.44c.3351356.1852265.7466171.1628139 1.0596518-.0577174.3130347-.2205312.472652-.6004544.4110482-.9783826l-.5087-3.12 2.1474-2.2012c.2615317-.268315.3511257-.6603195.2321205-1.0156075s-.4266393-.6142392-.7970205-.6708925l-2.9273-.4473-1.3183-2.8086c-.1647146-.3510492-.5175288-.5752272-.9053-.5752272s-.7405854.224178-.9053.5752272l-1.3183 2.8086-2.9273.4473c-.3703812.0566533-.6780153.3156045-.7970205.6708925s-.0294112.7472925.2321205 1.0156075zm2.1744-2.4388c.3297569-.0501449.6127478-.2615252.7544-.5635l.6396-1.363.64 1.3633c.1416522.3019748.4246431.5133551.7544.5635l1.5308.2343-1.1406 1.169c-.2216971.2271681-.322284.5461511-.271.8594l.2588 1.5859-1.2881-.7119c-.3010717-.1666737-.6667283-.1666737-.9678 0l-1.2881.7119.2588-1.5859c.051284-.3132489-.0493029-.6322319-.271-.8594l-1.1406-1.169z" />
            <path d="m8.4316 33.7188-.5087 3.12c-.06160376.3779282.09801349.7578514.41104821.9783826.31303471.2205313.72451617.2429439 1.05965179.0577174l2.6064-1.4403 2.6064 1.44c.3351356.1852265.7466171.1628139 1.0596518-.0577174.3130347-.2205312.472652-.6004544.4110482-.9783826l-.5087-3.12 2.1474-2.2012c.2615317-.268315.3511257-.6603195.2321205-1.0156075s-.4266393-.6142392-.7970205-.6708925l-2.9273-.4473-1.3183-2.8086c-.1647146-.3510492-.5175288-.5752272-.9053-.5752272s-.7405854.224178-.9053.5752272l-1.3183 2.8086-2.9273.4473c-.37038116.0566533-.67801534.3156045-.79702049.6708925-.11900516.355288-.02941123.7472925.23212049 1.0156075zm2.1744-2.4388c.3297569-.0501449.6127478-.2615252.7544-.5635l.6396-1.363.64 1.3633c.1416522.3019748.4246431.5133551.7544.5635l1.5308.2343-1.1406 1.169c-.2216971.2271681-.322284.5461511-.271.8594l.2588 1.5859-1.2881-.7119c-.3010717-.1666737-.6667283-.1666737-.9678 0l-1.2881.7119.2588-1.5859c.051284-.3132489-.0493029-.6322319-.271-.8594l-1.1406-1.169z" />
            <path d="m36.4316 33.7188-.5087 3.12c-.0616038.3779282.0980135.7578514.4110482.9783826.3130347.2205313.7245162.2429439 1.0596518.0577174l2.6064-1.4403 2.606 1.44c.3351607.1854603.7468134.1631684 1.0599868-.0574005s.4728318-.6006532.4111132-.9786995l-.5087-3.12 2.1474-2.2012c.2615131-.268246.3511607-.6601608.2322701-1.0154213-.1188906-.3552606-.4263756-.6142719-.7966701-.6710787l-2.9278-.4473-1.3183-2.8086c-.1647146-.3510492-.5175288-.5752272-.9053-.5752272s-.7405854.224178-.9053.5752272l-1.3183 2.8086-2.9273.4473c-.3703812.0566533-.6780153.3156045-.7970205.6708925s-.0294112.7472925.2321205 1.0156075zm2.1744-2.4388c.3297569-.0501449.6127478-.2615252.7544-.5635l.6396-1.363.64 1.3633c.1416522.3019748.4246431.5133551.7544.5635l1.5308.2343-1.1406 1.169c-.2216971.2271681-.322284.5461511-.271.8594l.2583 1.5859-1.2876-.7119c-.301243-.1660092-.666557-.1660092-.9678 0l-1.2881.7119.2588-1.5859c.051284-.3132489-.0493029-.6322319-.271-.8594l-1.1406-1.169z" />
            <path d="m7 43h12c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-12c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1z" />
            <path d="m45 41h-22c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h22c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
            <path d="m45 45h-38c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h38c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
            <path d="m7 51h24c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-24c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1z" />
            <path d="m45 49h-10c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h10c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
            <path d="m45 53h-38c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h38c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
          </svg>
        </i>
      );
    case "arrow-right":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 492.004 492.004"
          >
            <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136 c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002 v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864 c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872 l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
          </svg>
        </i>
      );
    case "arrow-left":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 240.823 240.823">
            <path d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179 l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816 C52.942,116.507,52.942,124.327,57.633,129.007z" />
          </svg>
        </i>
      );
    case "full-screen":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M335.085,207.085L469.333,72.837V128c0,11.782,9.551,21.333,21.333,21.333S512,139.782,512,128V21.335 c0-0.703-0.037-1.406-0.106-2.107c-0.031-0.316-0.09-0.622-0.135-0.933c-0.054-0.377-0.098-0.755-0.172-1.13 c-0.071-0.358-0.169-0.705-0.258-1.056c-0.081-0.323-0.152-0.648-0.249-0.968c-0.104-0.345-0.234-0.678-0.355-1.015 c-0.115-0.319-0.22-0.641-0.35-0.956c-0.13-0.315-0.284-0.616-0.428-0.923c-0.153-0.324-0.297-0.651-0.467-0.969 c-0.158-0.294-0.337-0.574-0.508-0.86c-0.186-0.311-0.362-0.626-0.565-0.93c-0.211-0.316-0.447-0.613-0.674-0.917 c-0.19-0.253-0.366-0.513-0.568-0.76c-0.443-0.539-0.909-1.058-1.402-1.551c-0.004-0.004-0.007-0.008-0.011-0.012 c-0.004-0.004-0.008-0.006-0.011-0.01c-0.494-0.493-1.012-0.96-1.552-1.403c-0.247-0.203-0.507-0.379-0.761-0.569 c-0.303-0.227-0.6-0.462-0.916-0.673c-0.304-0.203-0.619-0.379-0.931-0.565c-0.286-0.171-0.565-0.35-0.859-0.508 c-0.318-0.17-0.644-0.314-0.969-0.467c-0.307-0.145-0.609-0.298-0.923-0.429c-0.315-0.13-0.637-0.236-0.957-0.35 c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.646-0.168-0.969-0.249c-0.351-0.089-0.698-0.187-1.055-0.258 c-0.375-0.074-0.753-0.119-1.13-0.173c-0.311-0.044-0.617-0.104-0.933-0.135C492.072,0.037,491.37,0,490.667,0H384 c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h55.163L304.915,176.915 c-8.331,8.331-8.331,21.839,0,30.17S326.754,215.416,335.085,207.085z" />
            <path d="M176.915,304.915L42.667,439.163V384c0-11.782-9.551-21.333-21.333-21.333C9.551,362.667,0,372.218,0,384v106.667 c0,0.703,0.037,1.405,0.106,2.105c0.031,0.315,0.09,0.621,0.135,0.933c0.054,0.377,0.098,0.756,0.173,1.13 c0.071,0.358,0.169,0.704,0.258,1.055c0.081,0.324,0.152,0.649,0.249,0.969c0.104,0.344,0.233,0.677,0.354,1.013 c0.115,0.32,0.22,0.642,0.35,0.957c0.13,0.315,0.284,0.616,0.429,0.923c0.153,0.324,0.297,0.651,0.467,0.969 c0.158,0.294,0.337,0.573,0.508,0.859c0.186,0.311,0.362,0.627,0.565,0.931c0.211,0.316,0.446,0.612,0.673,0.916 c0.19,0.254,0.366,0.514,0.569,0.761c0.443,0.54,0.91,1.059,1.403,1.552c0.004,0.004,0.006,0.008,0.01,0.011 c0.004,0.004,0.008,0.007,0.012,0.011c0.493,0.492,1.012,0.959,1.551,1.402c0.247,0.203,0.507,0.379,0.76,0.568 c0.304,0.227,0.601,0.463,0.917,0.674c0.303,0.203,0.618,0.379,0.93,0.565c0.286,0.171,0.565,0.35,0.86,0.508 c0.318,0.17,0.645,0.314,0.969,0.467c0.307,0.145,0.609,0.298,0.923,0.428c0.315,0.13,0.636,0.235,0.956,0.35 c0.337,0.121,0.67,0.25,1.015,0.355c0.32,0.097,0.645,0.168,0.968,0.249c0.351,0.089,0.698,0.187,1.056,0.258 c0.375,0.074,0.753,0.118,1.13,0.172c0.311,0.044,0.618,0.104,0.933,0.135c0.7,0.069,1.402,0.106,2.104,0.106 c0,0,0.001,0,0.001,0H128c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H72.837l134.248-134.248 c8.331-8.331,8.331-21.839,0-30.17S185.246,296.584,176.915,304.915z" />
            <path d="M507.736,503.425c0.226-0.302,0.461-0.598,0.671-0.913c0.204-0.304,0.38-0.62,0.566-0.932 c0.17-0.285,0.349-0.564,0.506-0.857c0.17-0.318,0.315-0.646,0.468-0.971c0.145-0.306,0.297-0.607,0.428-0.921 c0.13-0.315,0.236-0.637,0.35-0.957c0.121-0.337,0.25-0.669,0.354-1.013c0.097-0.32,0.168-0.646,0.249-0.969 c0.089-0.351,0.187-0.698,0.258-1.055c0.074-0.375,0.118-0.753,0.173-1.13c0.044-0.311,0.104-0.617,0.135-0.933 c0.069-0.701,0.106-1.404,0.106-2.107V384c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333v55.163 L335.085,304.915c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17l134.248,134.248H384 c-11.782,0-21.333,9.551-21.333,21.333S372.218,512,384,512h106.667c0.703,0,1.405-0.037,2.105-0.106 c0.315-0.031,0.621-0.09,0.933-0.135c0.377-0.054,0.756-0.098,1.13-0.173c0.358-0.071,0.704-0.169,1.055-0.258 c0.324-0.081,0.649-0.152,0.969-0.249c0.344-0.104,0.677-0.233,1.013-0.354c0.32-0.115,0.642-0.22,0.957-0.35 c0.314-0.13,0.615-0.283,0.921-0.428c0.325-0.153,0.653-0.297,0.971-0.468c0.293-0.157,0.572-0.336,0.857-0.506 c0.312-0.186,0.628-0.363,0.932-0.566c0.315-0.211,0.611-0.445,0.913-0.671c0.255-0.191,0.516-0.368,0.764-0.571 c0.535-0.439,1.05-0.903,1.54-1.392c0.008-0.007,0.016-0.014,0.023-0.021s0.014-0.016,0.021-0.023 c0.488-0.49,0.952-1.004,1.392-1.54C507.368,503.941,507.545,503.68,507.736,503.425z" />
            <path d="M72.837,42.667H128c11.782,0,21.333-9.551,21.333-21.333C149.333,9.551,139.782,0,128,0H21.333c0,0-0.001,0-0.001,0 c-0.702,0-1.405,0.037-2.104,0.106c-0.316,0.031-0.622,0.09-0.933,0.135c-0.377,0.054-0.755,0.098-1.13,0.172 c-0.358,0.071-0.705,0.169-1.056,0.258c-0.323,0.081-0.648,0.152-0.968,0.249c-0.345,0.104-0.678,0.234-1.015,0.355 c-0.319,0.115-0.641,0.22-0.956,0.35c-0.315,0.131-0.618,0.284-0.925,0.43c-0.323,0.152-0.65,0.296-0.967,0.466 c-0.295,0.158-0.575,0.338-0.862,0.509C10.106,3.215,9.791,3.39,9.488,3.593c-0.317,0.212-0.615,0.448-0.92,0.676 C8.316,4.458,8.057,4.633,7.812,4.835C6.725,5.727,5.727,6.725,4.835,7.812C4.633,8.057,4.458,8.316,4.269,8.569 c-0.228,0.305-0.464,0.603-0.676,0.92c-0.203,0.303-0.378,0.617-0.564,0.928c-0.171,0.286-0.351,0.567-0.509,0.862 c-0.17,0.317-0.313,0.643-0.466,0.967c-0.145,0.307-0.299,0.61-0.43,0.925c-0.13,0.315-0.235,0.636-0.35,0.956 c-0.121,0.337-0.25,0.67-0.355,1.015c-0.097,0.32-0.168,0.645-0.249,0.968c-0.089,0.351-0.187,0.698-0.258,1.056 c-0.074,0.375-0.118,0.753-0.172,1.13c-0.044,0.311-0.104,0.618-0.135,0.933C0.037,19.928,0,20.63,0,21.333V128 c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333V72.837l134.248,134.248 c8.331,8.331,21.839,8.331,30.17,0s8.331-21.839,0-30.17L72.837,42.667z" />
          </svg>
        </i>
      );
    case "facebook":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M512,256C512,114.6,397.4,0,256,0S0,114.6,0,256,114.6,512,256,512c1.5,0,3,0,4.5-.1V312.7h-55V248.6h55V201.4c0-54.7,33.4-84.5,82.2-84.5,23.4,0,43.5,1.7,49.3,2.5v57.2H358.4c-26.5,0-31.7,12.6-31.7,31.1v40.8h63.5l-8.3,64.1H326.7V502.1C433.7,471.4,512,372.9,512,256Z" />
          </svg>
        </i>
      );
    case "twitter":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M256,0C114.637,0,0,114.637,0,256S114.637,512,256,512,512,397.363,512,256,397.363,0,256,0ZM372.887,199.6q.17,3.779.168,7.594c0,77.645-59.1,167.18-167.184,167.184h0A166.322,166.322,0,0,1,115.8,347.985a119.509,119.509,0,0,0,14.016.813,117.9,117.9,0,0,0,72.98-25.152,58.825,58.825,0,0,1-54.895-40.812,58.564,58.564,0,0,0,26.539-1.008,58.774,58.774,0,0,1-47.141-57.6c0-.266,0-.5.008-.75a58.354,58.354,0,0,0,26.613,7.348,58.809,58.809,0,0,1-18.191-78.445,166.825,166.825,0,0,0,121.117,61.395A58.791,58.791,0,0,1,356.98,160.188a117.856,117.856,0,0,0,37.313-14.262,58.989,58.989,0,0,1-25.84,32.5,117.189,117.189,0,0,0,33.742-9.254,119.411,119.411,0,0,1-29.309,30.43Zm0,0" />

          </svg>
        </i>
      );
    case "linkedin":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M256,0C114.637,0,0,114.637,0,256S114.637,512,256,512,512,397.363,512,256,397.363,0,256,0ZM181.609,387H119.262V199.426h62.348ZM150.438,173.812h-.406c-20.922,0-34.453-14.4-34.453-32.4,0-18.406,13.945-32.41,35.273-32.41s34.453,14,34.859,32.41c0,18-13.531,32.4-35.273,32.4ZM406.422,387h-62.34V286.652c0-25.219-9.027-42.418-31.586-42.418-17.223,0-27.48,11.6-31.988,22.8-1.648,4.008-2.051,9.609-2.051,15.215V387H216.113s.816-169.977,0-187.574h62.344v26.559c8.285-12.781,23.109-30.961,56.188-30.961,41.02,0,71.777,26.809,71.777,84.422Zm0,0" />
          </svg>
        </i>
      );
    case "loader":
      return (
        <i className={`custIcon custIcon-${props.type} ${addClassName} `}>
          <svg
            width="1em"
            height="1em"
            fill="currentcolor"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128">
            <g>
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.1" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.12" transform="rotate(15 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.15" transform="rotate(30 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.18" transform="rotate(45 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.22" transform="rotate(60 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.25" transform="rotate(75 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.29" transform="rotate(90 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.34" transform="rotate(105 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.34" transform="rotate(120 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.42" transform="rotate(135 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.47" transform="rotate(150 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.52" transform="rotate(165 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.57" transform="rotate(180 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.62" transform="rotate(195 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.66" transform="rotate(210 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.71" transform="rotate(225 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.75" transform="rotate(240 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.8" transform="rotate(255 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.84" transform="rotate(270 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.87" transform="rotate(285 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.91" transform="rotate(300 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.94" transform="rotate(315 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="0.96" transform="rotate(330 64 64)" />
              <path d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z" fill="#ffffff" fill-opacity="1" transform="rotate(345 64 64)" />
              <animateTransform attributeName="transform" type="rotate" values="0 64 64;15 64 64;30 64 64;45 64 64;60 64 64;75 64 64;90 64 64;105 64 64;120 64 64;135 64 64;150 64 64;165 64 64;180 64 64;195 64 64;210 64 64;225 64 64;240 64 64;255 64 64;270 64 64;285 64 64;300 64 64;315 64 64;330 64 64;345 64 64" calcMode="discrete" dur="2160ms" repeatCount="indefinite"></animateTransform>
            </g>
          </svg>
        </i>
      );
    // );
    default:
      return null;
  }
};
export default CustIcon;
// height={props.height}
// width={props.width}
// fill={props.fill}
