import React from "react";
import { useTranslation } from "react-i18next";

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className="Empty">
      <div className="nodata flex flex-col items-center">
        <img alt="" src={require("../../images/noData.png")} />
        <span className="mt-2 text-gray-800">{t("No data found")}</span>
      </div>
    </div>
  );
};

export default Empty;
