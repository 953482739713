import React from "react";
import { Link } from "react-router-dom";
import CustIcon from "../children/Svgs";
export const GamesContent = ({ match }) => {
  const { game } = match.params;

  const URL = `https://www.iamicon2020.com/games/${game}/`;

  return (
    <div className="loadTansection flex playGameSec flex-col min-h-screen overflow-hidden">
      <div className="pr-12 flex justify-end pt-8 pr-32">
        <Link to="/exhibition/games" className="btn-back">
          {" "}
          <CustIcon type="arrow-left" className="mr-2" /> Back
        </Link>
      </div>
      <iframe
        src={`${URL}?token=${localStorage.getItem("imicon_access_token")}`}
        frameBorder="0"
        allowFullScreen="allowfullscreen"
      ></iframe>
    </div>
  );
};
