import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import "./css/style.scss";
import "./css/slick.css";
import "./css/slick-theme.css";
import "toasted-notes/src/styles.css";
import { SignIn } from "./pages/SignIn";
import Registration from "./pages/Registration";
import Intro from "./pages/Intro";
import { Exhibition } from "./pages/Exhibition";
import history from "./utils/History";
import UserDetails from "./pages/UserDetails";
import Privacy from "./pages/Privacy";
import { accessTokenState } from "./atoms/atoms";
import { useRecoilState } from "recoil";
import i18n from "./utils/in18";
import { useGoogleAnalytics } from "./utils/GoogleAnalytics/analytics";

function App(props) {
  useGoogleAnalytics();
  const location = useLocation();
  const [, setAccessToken] = useRecoilState(accessTokenState);
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    const in18Code = localStorage.getItem("i18nextLng");

    if (in18Code) {
      i18n.changeLanguage(in18Code);
    }

    if (!token && !localStorage.getItem("imicon_access_token")) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/exhibition/games" ||
      location.pathname === "/exhibition/virtualtour"
    ) {
      if (token) {
        const code = new URLSearchParams(location.search).get("code");
        const language_id = new URLSearchParams(location.search).get(
          "language_id"
        );
        const id = new URLSearchParams(location.search).get("id");
        const last_login = new URLSearchParams(location.search).get(
          "last_login"
        );
        setAccessToken(token);
        localStorage.setItem("imicon_user_last_login", last_login);
        localStorage.setItem("imicon_access_token", token);
        localStorage.setItem(
          "language_settings",
          JSON.stringify({
            code: code,
            language_id: language_id,
          })
        );
        i18n.changeLanguage(code);
        localStorage.setItem("imicon_user_id", id);
        history.push(location.pathname);
      }
    }
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (
      location.pathname !== "/exhibition/games" &&
      location.pathname !== "/exhibition/virtualtour"
    ) {
    if (token) {
      const code = new URLSearchParams(location.search).get("code");
      const language_id = new URLSearchParams(location.search).get(
        "language_id"
      );
      const id = new URLSearchParams(location.search).get("id");
      const last_login = new URLSearchParams(location.search).get("last_login");

      setAccessToken(token);
      localStorage.setItem("imicon_user_last_login", last_login);
      localStorage.setItem("imicon_access_token", token);
      localStorage.setItem(
        "language_settings",
        JSON.stringify({
          code: code,
          language_id: language_id,
        })
      );
      i18n.changeLanguage(code);
      localStorage.setItem("imicon_user_id", id);
      history.push("/exhibition/lobby");
    }
  }
  }, [token]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login" render={(props) => <SignIn {...props} />} />
        <Route path="/details" render={(props) => <UserDetails {...props} />} />
        <Route path="/registration" component={Registration} />
        <Route path="/privacy-policy/" component={Privacy} />
        <PrivateRoute path="/intro" component={Intro} />
        <PrivateRoute path="/exhibition" component={Exhibition} />
      </Switch>
    </>
  );
}

export default App;
