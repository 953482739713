import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { pointsState } from "../atoms/atoms";
import { CONFLICT_ON_FEEDBACK, FEED_BACK_UPDATE } from "../constants/constants";
import {
  ADD_TO_RESOURCE,
  INSERT_USER_FEED_BACK,
  INSERT_USER_REWARD_TYPE,
  INSERT_USER_REWARD_RESOURCE,
} from "../graphql/mutation";
import { GET_LOBBY_AGENDA_BY_TYPE, GET_FEED_BACK } from "../graphql/query";
import Agenda from "./children/Agenda";
import Feedback from "./children/Feedback";
import Loader from "./children/Loader";
import CustIcon from "./children/Svgs";

const InformationDesk = () => {
  const { t } = useTranslation();
  const [modalBrochures, setBrochures] = useState(false);
  const [brochure, setBrochure] = useState("");
  const [points] = useRecoilState(pointsState);
  const [isFeedBackOpen, setIsFeedBackOpen] = useState(false);

  const [insertUserRewardResource] = useMutation(INSERT_USER_REWARD_RESOURCE);

  const [storeToMyResource, { loading: loadingMyResource }] = useMutation(
    ADD_TO_RESOURCE
  );

  const { data: dataBooth, loading: loadingBooth } = useQuery(
    GET_LOBBY_AGENDA_BY_TYPE,
    {
      variables: {
        type: "lobby",
        resource_type : "brochure"
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    GET_FEED_BACK_QUERY,
    { data: FeedBackData, loading: FeedBackLoading },
  ] = useLazyQuery(GET_FEED_BACK, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (dataBooth) {
      setBrochure(
        dataBooth?.booth[0]?.resources?.find(
          (resource) => resource.resource_type.name === "brochure"
        )
      );
    }
  }, [dataBooth]);

  const handleAddToBag = async (resource_id, pointsArg) => {
    try {
      await insertUserRewardResource({
        variables: {
          resource_id,
          points: points + pointsArg,
        },
      });
    } catch (error) {
      console.log(error);
    }
    storeToMyResource({
      variables: {
        resource_id,
      },
    });
  };

  const [
    INSERT_USER_FEED_BACK_MUTAION,
    { loading: loadingtUserFeedback },
  ] = useMutation(INSERT_USER_FEED_BACK);

  const [SEND_USER_REWARD] = useMutation(INSERT_USER_REWARD_TYPE);

  const onSubmitReview = (
    review,
    platformRating,
    contentRating,
    overallRating,
    isChecked
  ) => {

    INSERT_USER_FEED_BACK_MUTAION({
      variables: {
        comments: review,
        platform_rating: platformRating,
        content_rating: contentRating,
        overall_rating: overallRating,
        will_attend: isChecked,
      },
    });
    onInsertUserReward();
  };

  const handleKeyDownReview = (e) => {
    if (e.key === "Enter") {
      onSubmitReview();
    }
  };

  const onInsertUserReward = async () => {
    try {
      await SEND_USER_REWARD({
        variables: {
          type: CONFLICT_ON_FEEDBACK,
          points: FEED_BACK_UPDATE + points,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openMyFeedBackModal = async () => {
    try {
      GET_FEED_BACK_QUERY();
    } catch (err) {
      console.log(err);
    }
    setIsFeedBackOpen(true);
  };

  return (
    <section className="loadTansection flex informationDeskPage bg-cover item-center justify-center bg-fixed flex-col min-h-screen overflow-hidden">
      {loadingBooth ? (
        <Loader />
      ) : (
          <div className="infoBrochure">
            <div className="infoBrochureIcon">
              <CustIcon type="coin" className="mb-3" />
              <div
                className="icon_blk flex items-center"
                onClick={() => setBrochures(true)}
              >
                <CustIcon type="brochure" />
                {t("Agenda")}
              </div>
            </div>

            <div className="infoFeedbackIcon">
              <CustIcon type="coin" className="mb-3" />
              <div
                className="icon_blk flex flex-col items-center"
                onClick={() => openMyFeedBackModal()}
              >
                <CustIcon type="rating" />
                {t("Feedback")}
              </div>
            </div>

            {modalBrochures && (
              <Agenda
                onCloseAgendaTab={() => setBrochures(false)}
                src={brochure?.url}
                handleAddToBag={() =>
                  handleAddToBag(brochure.id, brochure.points)
                }
                loading={loadingMyResource}
              />
            )}
            {isFeedBackOpen && (
              <Feedback
                onCloseFeedBack={() => setIsFeedBackOpen(false)}
                data={FeedBackData}
                loading={FeedBackLoading}
                onSubmit={onSubmitReview}
                handleKeyDownReview={handleKeyDownReview}
                sending={loadingtUserFeedback}
              />
            )}
          </div>
        )}
    </section>
  );
};

export default InformationDesk;

// {require('../images/old/news-author-04.jpg')}
