import React, { useEffect, useState } from "react";
import { withApollo, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  accessTokenState,
  earningState,
  isCoinUIOpenState,
  pointsState,
} from "../atoms/atoms";
import { LOGIN } from "../graphql/mutation";
import history from "../utils/History";
import i18n from "../utils/in18";
import { disConnect } from "../utils/Socket";
import CustIcon from "./children/Svgs";

const SignIn = ({ client }) => {
  const { t } = useTranslation();

  const [LOGIN_MUTATION, { error, loading, data }] = useMutation(LOGIN);

  const [loginCode, setLoginCode] = useState("");

  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const resetPoints = useResetRecoilState(pointsState);
  const resetEarning = useResetRecoilState(earningState);
  const resetCoinUI = useResetRecoilState(isCoinUIOpenState);
  const [, setAccessToken] = useRecoilState(accessTokenState);

  useEffect(() => {
    if (data) {
      /** clean up exisiting sessions */
      disConnect();
      client.resetStore();
      client.restartWebsocketConnection();
      resetPoints();
      resetEarning();
      resetCoinUI();
      localStorage.clear();

      setAccessToken(data.login.access_token);
      localStorage.setItem("imicon_user_id", data.login.id);
      localStorage.setItem("imicon_access_token", data.login.access_token);
      localStorage.setItem("imicon_user_last_login", data.login.last_login);
      localStorage.setItem(
        "language_settings",
        JSON.stringify({
          code: data.login.language.code,
          language_id: data.login.language_id,
        })
      );

      i18n.changeLanguage(data.login.language.code);
      if (data?.login?.last_login) {
        history.push("/intro");
      } else {
        history.push("/details");
      }
    }
  }, [client, data]);

  useEffect(() => {
    if (selectedOption === "fr") {
      i18n.changeLanguage("fr");
    } else if (selectedOption === "en") {
      i18n.changeLanguage("en");
    } else if (selectedOption === "ru") {
      i18n.changeLanguage("ru");
    }
  }, [selectedOption]);

  // const login = () => {
  //   localStorage.clear();
  //   LOGIN_MUTATION({
  //     variables: {
  //       username: loginCode.toLowerCase(),
  //     },
  //   });
  // };
  const login = async () => {
    localStorage.clear();
    try {
      await LOGIN_MUTATION({
        variables: {
          username: loginCode.toLowerCase(),
        },
        context: {
          headers: {
            "x-expo-domain": window.location.hostname,
          },
        },
      });
    } catch (err) {
      console.log("error");
    }
  };

  const handleChange = (e) => {
    setLoginCode(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <>
      <div className="loadTansection flex signInPage transition duration-300 flex-col min-h-screen overflow-hidden relative">
        <div className="switchLanguage">
          <div className="App">
            <div className="stv-radio-buttons-wrapper">
              <input
                type="radio"
                className="stv-radio-button"
                name="english"
                onChange={handleLanguageChange}
                value="en"
                checked={selectedOption === "en"}
                id="button1"
              />
              <label htmlFor="button1">English</label>
              <input
                type="radio"
                className="stv-radio-button"
                name="french"
                value="fr"
                onChange={handleLanguageChange}
                checked={selectedOption === "fr"}
                id="button2"
              />
              <label htmlFor="button2">French</label>
              <input
                type="radio"
                className="stv-radio-button"
                name="russian"
                value="ru"
                onChange={handleLanguageChange}
                checked={selectedOption === "ru"}
                id="button3"
              />
              <label htmlFor="button3">Russian</label>
            </div>
          </div>
        </div>

        <div className="loginLogo">
          <img
            src={require(`../images/logo-${selectedOption}.png`)}
            alt="logo"
          />
        </div>
        <div className="codeCard_bg">
          <div className="loginContainer">
            <div className="cadilaLogo">
              <img
                src={require(`../images/Cadila-Logo-${selectedOption}.svg`)}
                alt={`${selectedOption}`}
              />
            </div>
            <div className="loginText text-white ">
              {t("Welcome to the largest science & innovation conclave")}
            </div>
            <div className="loginWorld text-black">
              {selectedOption === "fr" && (
                <img alt="french map" src={require("../images/french.png")} />
              )}
              {selectedOption === "en" && (
                <img alt="world map" src={require("../images/world.png")} />
              )}
              {selectedOption === "ru" && (
                <img alt="russian map" src={require("../images/russian.png")} />
              )}
            </div>
            <div className="codeCard_blk text-black">
              <h3>{t("Welcome")}</h3>
              <div className="form-control relative">
                <input
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="appearance-none border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base h-3/4"
                  id="badgeNumber"
                  type="text"
                  placeholder={t("Your code")}
                  required
                />
                <button onClick={login} className="">
                  {!loading ? (
                    <CustIcon type="arrow-right" />
                  ) : (
                    <CustIcon type="coin" size="xs" />
                  )}
                </button>
              </div>

              {error?.graphQLErrors.length && (
                <span status="error" className="center-content">
                  {error.graphQLErrors.map((err) => {
                    return err.message;
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="portrait">
        <div className="overlayM">
          <div className="iconContainer">
            <div className="phone flex">
              <a
                href="https://api.intelliexpo.com/rest/apps/ios"
                className="flex mx-3"
              >
                <img src={require("../images/ios.png")} alt="" />
              </a>
              <a
                href="https://api.intelliexpo.com/rest/apps/android"
                className="flex mx-3"
              >
                <img src={require("../images/android.png")} alt="" />
              </a>
            </div>
            <p>{t("Please download app from the store!")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const SignInWithApollo = withApollo(SignIn);
export { SignInWithApollo as SignIn };
