import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SideBar = ({
  toggleSideBarMenu,
  onOpenGlobalChatModal,
  onOpenMyBagModal,
  onOpenMyProfileModal,
  onOpenMyContactModal,
  onOpenAttendeesModal,
  onOpenExhibitorsModal,
  onClickCertificates,
  onLogOut,
  onClickIntro,
  onClickRedeemCoins,
}) => {
  const { t } = useTranslation();
  const { code } = JSON.parse(localStorage.getItem("language_settings"));
  return (
    <div className={`overlay menu_${code}`} id="overlay" onClick={toggleSideBarMenu}>
      <ul className="menu">
        <li>
          <span onClick={onOpenMyProfileModal} className="focus:shadow-none">
            {t("My Profile")}
          </span>
        </li>
        <li>
          <Link
            to={`/exhibition/lobby`}
            className="focus:shadow-none lobbylink"
          >
            {t("Lobby")}
          </Link>
        </li>
        <li>
          <a href="#" data-link="tab2">
            {t("Navigation")}
          </a>
          <ul>
            <li>
              <Link
                to={`/exhibition/sessions`}
                className="focus:shadow-none"
                rel="Sessions"
              >
                {t("Nelson Mandela Hall")}
              </Link>
            </li>

            {code === "en" ?
              <li>
                <Link
                  to={`/exhibition/meetingroom`}
                  className="focus:shadow-none"
                  rel="Meeting Room"
                >
                  {t("Mahatma Gandhi Hall")}
                </Link>
              </li> : ""}
            <li>

              {
                code === "en" ?
                  <Link
                    to={`/exhibition/countries`}
                    className="focus:shadow-none"
                    rel="Exhibition Floor"
                  >
                    {t("Exhibition Lounge")}
                  </Link>
                  :
                  <Link
                    to={`/exhibition/floor`}
                    className="focus:shadow-none"
                    rel="Exhibition Floor"
                  >
                    {t("Exhibition Lounge")}
                  </Link>
              }

            </li>
            <li>
              <Link
                to={`/exhibition/games`}
                className="focus:shadow-none"
                rel="Expo Lounge"
              >
                {t("Entertainment Lounge")}
              </Link>
            </li>
            <li>
              <Link
                to={`/exhibition/virtualtour`}
                className="focus:shadow-none"
                rel="Virtual Tour"
              >
                {t("Gallery")}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <span onClick={onOpenAttendeesModal} className="focus:shadow-none">
            {t("Attendees")}
          </span>
        </li>

        <li>
          <span onClick={onOpenMyContactModal} className="focus:shadow-none">
            {t("My Contacts")}
          </span>
        </li>

        <li>
          <span onClick={onOpenMyBagModal} className="focus:shadow-none">
            {t("My Bag")}
          </span>
        </li>

        <li>
          <span onClick={onOpenGlobalChatModal} className="focus:shadow-none">
            {t("Chat")}
          </span>
        </li>

        {/* <li>
          <span onClick={onOpenExhibitorsModal} className="focus:shadow-none">
            {t("Exhibitors")}
          </span>
        </li> */}

        <li>
          <span className="focus:shadow-none" onClick={onClickIntro}>
            {t("Intro")}
          </span>
        </li>

        <li>
          <span className="focus:shadow-none" onClick={onClickCertificates}>
            {t("Request Certificate")}
          </span>
        </li>
        <li>
          <span className="focus:shadow-none" onClick={onClickRedeemCoins}>
            {t("Redeem Coins")}
          </span>
        </li>

        <li>
          <span className="focus:shadow-none" onClick={onLogOut}>
            {t("Logout")}
          </span>
        </li>
      </ul>
      <div className="flex footer justify-center pb-4 px-4">
        <a href="https://intelliexpo.com/" target="_blank"><img alt="" src={require("../../images/Poweredby-white.svg")} /></a>
      </div>
    </div>
  );
};
export default SideBar;
