import io from "socket.io-client";
import Emitter from "./Emmiter";
let didReconnect = false;
const socket = io("https://api.intelliexpo.com/", {
  transports: ["websocket"],
  pingTimeout: 30000,
});

socket.on("disconnect", (err) => {
  console.log(err);
});

socket.on("reconnect", function (attemptNumber) {
  didReconnect = true;
  console.log("Reconnect");
});

socket.on("reconnect_attempt", function (attemptNumber) {
  console.log("Reconnect Attempt");
});

socket.on("connect_error", function (error) {
  console.log(error);
});

socket.on("error", function (error) {
  console.log(error);
});

socket.on("connect", (err) => {
  if(didReconnect){
    const user_id = localStorage.getItem("imicon_user_id");
    socket.emit("joinSelf",user_id);
    didReconnect = false;
  }
  console.log(err);
});

socket.on("ping", () => {
  console.log("ping");
});

socket.on("pong", (latency) => {
  console.log("pong");
});

socket.on("presence", (data) => {
  Emitter.emit("presence", data);
});

socket.on("receiveConferenceMsg", (data) => {
  Emitter.emit("receiveConferenceMsg", data);
});

socket.on("receiveGlobalChatMsg", (data) => {
  Emitter.emit("receiveGlobalChatMsg", data);
});

socket.on("receiveConversation", (data) => {
  Emitter.emit("receiveConversation", data);
});

socket.on("notifications", (data) => {
  Emitter.emit("notifications", data);
});

const disConnect = () => {
  socket.disconnect();
};

const connect= () => {
  socket.connect();
}

const joinConfRoom = (confId) => {
  socket.emit("joinConfRoom", confId);
};

const joinConversation = (conversationId) => {
  socket.emit("joinConversation", conversationId);
};

const leaveConversation = (conversationId) => {
  socket.emit("leaveConversation", conversationId);
};

const sendConversation = (data) => {
  socket.emit("sendConversation", data);
};

const sendConferenceMsg = (data) => {
  socket.emit("sendConferenceMsg", data);
};

const joinGlobalChatRoom = (data) => {
  socket.emit("joinGlobalChat", data);
};

const joinSelf = (data) => {
  socket.emit("joinSelf", data);
};

const leaveGlobalChatRoom = (langId) => {
  socket.emit("leaveGlobalChat", langId);
};

const sendGlobalChatMsg = (data) => {
  socket.emit("sendGlobalChatMsg", data);
};

export {
  sendConferenceMsg,
  sendGlobalChatMsg,
  joinConfRoom,
  joinGlobalChatRoom,
  leaveGlobalChatRoom,
  disConnect,
  connect,
  joinConversation,
  sendConversation,
  leaveConversation,
  joinSelf,
};
