import React from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const Attendees = ({
  onCloseAttendeeModal,
  onSendConnectionRequest,
  loading,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <div className="fixed modelAttendee model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50" onClick={onCloseAttendeeModal}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseAttendeeModal}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div class="popup-header border-b border-solid w-full">
                <h3>{t("Attendees")}</h3>
              </div>
              <div className="popup-body w-full">
                <div className="users_data">
                  {!data && loading ? (
                    <Loader />
                  ) : (
                      data &&
                      data.user.map((u, index) => (
                        <div
                          key={index}
                          className="user-profile-row"
                          id="user_row_2001"
                        >
                          <div className="user-profile-data">
                            <div className="user-profile-image">
                              <img
                                className="img-responsive user-profile-img"
                                src={u.photo}
                                width="32"
                              />
                            </div>
                            <div className="user-profile-info">
                              <p className="user-profile-name">
                                <span
                                  className="checkProfile"
                                  data-id="2001"
                                  data-email="2001"
                                >
                                  {u.firstname} {u.lastname}
                                </span>
                              </p>
                            </div>
                          </div>
                          {!u.user_chats_to.length && !u.user_chats_from.length && (
                            <div className="user-profile-more">
                              <button
                                title="Click to chat"
                                className="start_chat_btn"
                                onClick={() => {
                                  onSendConnectionRequest(u.id);
                                }}
                              >
                                <img src={require("../../images/hand.png")} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendees;
