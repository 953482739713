import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import "react-tabs/style/react-tabs.css";
import { STORE_USER_FORM, INSERT_USER_REWARD } from "../graphql/mutation";
import history from "../utils/History";
import { pointsState } from "../atoms/atoms";
import { FIRST_TIME_LOGIN } from "../constants/constants";
import { useRecoilState } from "recoil";
import { GET_USER_DETAILS } from "../graphql/query";
import Loader from "./children/Loader";
const { REACT_APP_CLOUDFRONT_URL } = process.env;
const UserDetails = (props) => {
  const { t } = useTranslation();

  const user_id = localStorage.getItem("imicon_user_id");
  const { code } = JSON.parse(localStorage.getItem("language_settings"));
  const { register, handleSubmit, setValue, errors } = useForm();
  const [points] = useRecoilState(pointsState);
  const [storeUserForm, { loading: loadingUserStoreForm }] = useMutation(
    STORE_USER_FORM
  );

  const { data, loading } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: user_id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [SEND_USER_REWARD] = useMutation(INSERT_USER_REWARD);
  useEffect(() => {
    if (data) {
      setValue("firstname", data.user_by_pk.firstname);
      setValue("lastname", data.user_by_pk.lastname);
    }
  }, [data]);

  return (
    <div className="loadTansection flex signUpPage transition duration-300 bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      <video
        autoPlay
        name="videoFrame"
        className="loginVideo object-cover w-screen h-screen"
        id="videoFrame"
      >
        <source
          src={`${REACT_APP_CLOUDFRONT_URL}/videos/speech/speech_${code}.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="authenticationSec flex-grow flex items-center justify-center">
        <div className="flex flex-col items-center h-full justify-between loginform px-8 py-5 w-full">
          {/* <img alt="" src={require("../images/<img alt="" src={require('../images/logo.png')} />")} /> */}
          <div className="detailsLogo">
            <img alt="" src={require("../images/logo.png")} />
          </div>
          {loading && !data ? (
            <Loader />
          ) : (
            data && (
              <div className="bg-white rounded-xl p-4">
                <h3 className="mt-0 mb-0 font-bold text-black">
                  {t("Register")}
                </h3>
                <div className="max-w-xs">
                  <form
                    onSubmit={handleSubmit(async (formDetails) => {
                      if (user_id) {
                        await storeUserForm({
                          variables: {
                            id: user_id,
                            last_login: true,
                            ...formDetails,
                          },
                        });
                        await SEND_USER_REWARD({
                          variables: {
                            points: FIRST_TIME_LOGIN + points,
                          },
                        });
                        history.push("/intro");
                      }
                    })}
                  >
                    <div className="text-white pt-3">
                      <div className="flex flex-col md:flex-row flex-wrap row">
                        <div className="w-full">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="firstname"
                            type="text"
                            ref={register({ required: true })}
                            placeholder={t("Type your First Name")}
                          />
                          {errors.firstname && "First Name is required"}
                        </div>
                        <div className="w-full">
                          <input
                            className="msg-input w-full px-3 py-2 mb-3 border rounded mt-1 text-black"
                            name="lastname"
                            ref={register({ required: true })}
                            type="text"
                            placeholder={t("Type your Last Name")}
                          />
                          {errors.lastname && "Last Name is required"}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="border w-full justify-center px-4 py-2 rounded cursor-pointer btn-submit"
                      >
                        {loadingUserStoreForm
                          ? `${t("Sending")}`
                          : `${t("Submit")}`}
                      </button>
                    </div>
                  </form>
                </div>
                ​
              </div>
            )
          )}
          <div className="detailsFootLogo">
            <img alt="" src={require("../images/Cadila-Logo.png")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
