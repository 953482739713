import axios from 'axios';
const { REACT_APP_ENDPOINT } = process.env;

const headers = () => {
    return {
        "headers": {
            "authorization": `Bearer ${localStorage.getItem('imicon_access_token')}`
        }
    }
}

export const getSignedURL = (ext) => axios.get(`${REACT_APP_ENDPOINT}/rest/signed-upload-url/${ext}`, headers() );