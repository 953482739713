import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { pointsState } from "../atoms/atoms";
import {
  INSERT_USER_REWARD_RESOURCE,
  INSERT_USER_VISITS,
} from "../graphql/mutation";
import { GET_BOOTH_DETAILS_BY_TYPE } from "../graphql/query";
import history from "../utils/History";
import CustIcon from "./children/Svgs";
import VideoPopUp from "./children/VideoPopUp";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

function ExhibitionMain() {
  const { t } = useTranslation();
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  const [src, setSrc] = useState(
    `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/lobby_${code}.mp4`
  );

  const [type, setType] = useState("lobby");
  const [hint, setHint] = useState(true);
  const [selectedResource, setSelectedResource] = useState("");
  const [isVideoPopUp, setIsVideoPopUp] = useState();

  const [points] = useRecoilState(pointsState);

  const [insertUserRewardResource] = useMutation(INSERT_USER_REWARD_RESOURCE);
  const [insertUserVisit] = useMutation(INSERT_USER_VISITS);

  const { data: dataLobby, loading: loadingLobby } = useQuery(
    GET_BOOTH_DETAILS_BY_TYPE,
    {
      variables: {
        type: "lobby",
        resource_type: "video",
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    const videoEle = document.getElementById("videoFrame");

    if (type !== "lobby") {
      videoEle.loop = false;
    }

    if (dataLobby) {
      insertUserVisit({
        variables: {
          booth_id: dataLobby?.booth[0]?.id,
          type: "Lobby",
        },
      });
    }
  }, [src, type, dataLobby]);

  const onCloseVideoModal = () => {
    setIsVideoPopUp(false);
  };

  const handlePlayVideo = async (resource) => {
    setIsVideoPopUp(true);
    setSelectedResource(resource);
  };

  const onVideoEnded = async () => {
    try {
      await insertUserRewardResource({
        variables: {
          resource_id: selectedResource.id,
          points: points + selectedResource.points,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onRoomClick = (type) => {
    setType(type);
    setHint(false);
    switch (type) {
      case 1:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/mandela_walk_${code}.mp4`
        );
        break;
      case 2:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/game_walk_${code}.mp4`
        );
        break;
      case 3:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/booth_walk_${code}.mp4`
        );
        break;
      case 4:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/gallery_walk_${code}.mp4`
        );
        break;
      case 5:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/mahatma_walk_${code}.mp4`
        );
        break;
      case 6:
        setSrc(
          `${REACT_APP_CLOUDFRONT_URL}/videos/lobby/infodesk_walk_${code}.mp4`
        );
        break;
      default:
        break;
    }
  };

  const onVideoEndedEvent = () => {
    switch (type) {
      case 3:
        switch (code) {
          case "en":
            history.push("/exhibition/countries");
            break;
          case "fr":
            history.push("/exhibition/floor");
            break;
          case "ru":
            history.push("/exhibition/floor");
            break;
          default:
            break;
        }
        break;
      case 5:
        history.push("/exhibition/meetingroom");
        break;
      case 1:
        history.push("/exhibition/sessions");
        break;
      case 2:
        history.push("/exhibition/games");
        break;
      case 4:
        history.push("/exhibition/virtualtour");
        break;
      case 6:
        history.push("/exhibition/informationdesk");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={`loadTansection exhibitionMainPage transition ease-in overflow-hidden lag_${code}`}
    >
      <video
        key={src}
        loop
        autoPlay
        muted
        id="videoFrame"
        name="videoFrame"
        className="object-cover w-screen h-screen"
        onEnded={onVideoEndedEvent}
      >
        <source src={src} type="video/mp4" />
      </video>
      {hint && (
        <div data-action="lobby" className={`lobbyScreen open ${code}`}>
          <div className="dotPosition">
            <span
              className="hint nelsonMandela seminarhall my-second-step"
              rel={t("Nelson Mandela Hall")}
              onClick={() => onRoomClick(1)}
            ></span>
            <span
              className="hint game meetingroom my-third-step"
              rel={t("Entertainment Lounge")}
              onClick={() => onRoomClick(2)}
            ></span>
            <span
              className="hint exhibitionbooths my-fourth-step"
              rel={t("Exhibition Lounge")}
              onClick={() => onRoomClick(3)}
            ></span>
            <span
              className="hint gallary virtualtour my-fifth-step"
              rel={t("Gallery")}
              onClick={() => onRoomClick(4)}
            ></span>
            <span
              className="hint mahatmaGandhi relaxingzone my-sixth-step"
              rel={t("Mahatma Gandhi Hall")}
              onClick={() => onRoomClick(5)}
            ></span>
            <span
              className="hint InformationDesk my-seventh-step"
              rel={t("Help Desk")}
              onClick={() => onRoomClick(6)}
            ></span>
            {dataLobby &&
              dataLobby?.booth[0]?.resources &&
              dataLobby.booth[0].resources.map((resource, index) => (
                <button
                  key={index}
                  onClick={() => handlePlayVideo(resource)}
                  className={`videoMarker video_00${index + 1} my-eighth-step`}
                >
                  <CustIcon type="coin" size="md" />
                </button>
              ))}
          </div>
        </div>
      )}
      {isVideoPopUp && (
        <VideoPopUp
          title={selectedResource.name}
          src={selectedResource.url}
          onVideoEnded={onVideoEnded}
          onCloseVideoModal={onCloseVideoModal}
          loading={loadingLobby}
        />
      )}
    </div>
  );
}

export default ExhibitionMain;
