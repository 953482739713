import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CADILA_CORPORATE_TOUR,
  RUSSIA_VIDEO,
  KENYA_VIDEO,
  SRILANKA_VIDEO,
  CONFLICT_ON_CADILA_CORPORATE_VIDEO,
  CONFLICT_ON_RUSSIA_VIDEO,
  CONFLICT_ON_KENYA_VIDEO,
  CONFLICT_ON_SRILANKA_VIDEO,
} from "../constants/constants";
import Tour from "./children/360";
import { useMutation } from "react-apollo";
import VideoPopUp from "./children/VideoPopUp";
import { INSERT_USER_REWARD_TYPE } from "../graphql/mutation";
import { useRecoilState } from "recoil";
import { pointsState } from "../atoms/atoms";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const VirtualTour = () => {
  const [isTour, setIsTour] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [isKeGallery, setIsKeGallery] = useState(false);
  const [isLkGallery, setIsLkGallery] = useState(false);
  const [isRuGallery, setIsRuGallery] = useState(false);
  const [points] = useRecoilState(pointsState);
  const { t } = useTranslation();
  const [SEND_USER_REWARD_TYPE] = useMutation(INSERT_USER_REWARD_TYPE);
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  const startGallery = () => {
    setIsGallery(true);
  };
  const startKeGallery = () => {
    setIsKeGallery(true);
  };
  const startLkGallery = () => {
    setIsLkGallery(true);
  };
  const startRuGallery = () => {
    setIsRuGallery(true);
  };

  const endGallery = () => {
    setIsGallery(false);
  };
  const endKeGallery = () => {
    setIsKeGallery(false);
  };
  const endLkGallery = () => {
    setIsLkGallery(false);
  };
  const endRuGallery = () => {
    setIsRuGallery(false);
  };

  const startTour = () => {
    setIsTour(true);
  };

  const endTour = () => {
    setIsTour(false);
  };

  const onVideoEndedKE = async () => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          points: points + KENYA_VIDEO,
          type: CONFLICT_ON_KENYA_VIDEO,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onVideoEndedLK = async () => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          points: points + SRILANKA_VIDEO,
          type: CONFLICT_ON_SRILANKA_VIDEO,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onVideoEndedCadilaCop = async () => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          points: points + CADILA_CORPORATE_TOUR,
          type: CONFLICT_ON_CADILA_CORPORATE_VIDEO,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onVideoEndedRU = async () => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          points: points + RUSSIA_VIDEO,
          type: CONFLICT_ON_RUSSIA_VIDEO,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="loadTansection flex virtualTourPage bg-cover bg-fixed flex-col items-center justify-center min-h-screen overflow-hidden">
      <div className="vrvideolist">
        <div
          className={code === "ru" ? "videothumb videothumb_ru" : "videothumb"}
          onClick={startTour}
        >
          <img src={require("../images/360.png")} alt="" />
          <span>{t("Cadila 360 Tour")}</span>
        </div>
        {(code === "en" || code === "ru") && (
          <>
            <div
              className={
                code === "en" ? "videothumb" : "videothumb videothumb_ru"
              }
              onClick={startGallery}
            >
              <img src={require("../images/videotitle.png")} alt="" />
              <span>{t("Cadila Corporate Film")}</span>
            </div>
            {isGallery && (
              <VideoPopUp
                onVideoEnded={onVideoEndedCadilaCop}
                title={t("Cadila Corporate Film")}
                src={`${REACT_APP_CLOUDFRONT_URL}/videos/360/cadila_film_${code}.mp4`}
                onCloseVideoModal={endGallery}
              />
            )}
          </>
        )}
        {code === "ru" && (
          <>
            <div className="videothumb videothumb_ru" onClick={startKeGallery}>
              <img src={require("../images/countries/ke.png")} alt="" />
              <span>{t("Kenya Gastro Session 2020")}</span>
            </div>
            {isKeGallery && (
              <VideoPopUp
                onVideoEnded={onVideoEndedKE}
                title={t("Kenya Gastro Session 2020")}
                src={`${REACT_APP_CLOUDFRONT_URL}/videos/nelson/playlist_${code}/record/kenya.mp4`}
                onCloseVideoModal={endKeGallery}
              />
            )}
            <div className="videothumb videothumb_ru" onClick={startLkGallery}>
              <img src={require("../images/countries/lk.png")} alt="" />
              <span>{t("Sri Lanka Gastro Session 2020")}</span>
            </div>
            {isLkGallery && (
              <VideoPopUp
                onVideoEnded={onVideoEndedLK}
                title={t("Sri Lanka Gastro Session 2020")}
                src={`${REACT_APP_CLOUDFRONT_URL}/videos/nelson/playlist_${code}/record/srilanka.mp4`}
                onCloseVideoModal={endLkGallery}
              />
            )}

            <div className="videothumb videothumb_ru" onClick={startRuGallery}>
              <img src={require("../images/countries/ru.png")} alt="" />
              <span>{t("Russia Gastro Session 2020")}</span>
            </div>
            {isRuGallery && (
              <VideoPopUp
                onVideoEnded={onVideoEndedRU}
                title={t("Russia Gastro Session 2020")}
                src={`${REACT_APP_CLOUDFRONT_URL}/videos/nelson/playlist_${code}/record/russia.mp4`}
                onCloseVideoModal={endRuGallery}
              />
            )}
          </>
        )}

        {isTour && <Tour onTourClose={endTour} />}
      </div>
    </section>
  );
};

export default VirtualTour;
