import React, { useEffect, useRef, useState } from "react";
import toast from "toasted-notes";
import {
  useLazyQuery,
  useMutation,
  useSubscription,
  withApollo,
} from "react-apollo";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { Route, Switch } from "react-router-dom";
import Ticker from "react-ticker";
import { useRecoilState } from "recoil";
import { accessTokenState, pointsState } from "../atoms/atoms";
import { isMobile } from "react-device-detect";
import {
  CERTIFICATE_REQUEST,
  COMPLETE_PROFILE,
  CONFLICT_ON_CERTIFICATE,
  CONFLICT_ON_PROFILE,
} from "../constants/constants";
import {
  DELETE_FROM_BY_BAG,
  INSERT_USER_CERTIFICATES,
  INSERT_USER_REWARD_TYPE,
  START_USER_CONVERSTION,
  STORE_GLOBAL_CHAT,
  STORE_PERSONAL_CHAT,
  UPDATE_USER_FORM,
} from "../graphql/mutation";
import {
  GET_ATTENDEES,
  GET_CONVERSTION_BY_ID,
  GET_GLOBAL_CHAT,
  GET_MY_BAG,
  GET_MY_CONTACTS,
  GET_USER_DETAILS,
} from "../graphql/query";
import {
  SUBSCRIBE_ON_REWARD,
  SUBSCRIBE_ON_PLAYLIST_VIDEO,
} from "../graphql/subscription";
import Emitter from "../utils/Emmiter";
import history from "../utils/History";
import PrivateRoute from "../utils/PrivateRoute";
import {
  joinConversation,
  joinGlobalChatRoom,
  joinSelf,
  leaveConversation,
  sendConversation,
  sendGlobalChatMsg,
} from "../utils/Socket";
import Booth from "./Booth";
import Attendees from "./children/Attendees";
import Certificate from "./children/Certificate";
import Coin from "./children/Coin";
import CoinInfoPop from "./children/CoinInfoPop";
import CoinsPopUp from "./children/CoinsPopUp";
import Countries from "./children/Countries";
import MyExhibitors from "./children/Exhibitor";
import GlobalChat from "./children/GlobalChat";
import MyBag from "./children/MyBag";
import MyProfile from "./children/MyProfile";
import PersonalChat from "./children/PersonalChat";
import SideBar from "./children/SideBar";
import CustIcon from "./children/Svgs";
import ExhibitionFloor from "./ExhibitionFloor";
import ExhibitionMain from "./ExhibitionMain";
import ExpoLounge from "./ExpoLounge";
import { GamesContent } from "./games/game";
import { Games } from "./games/gameIndex";
import InformationDesk from "./InformationDesk";
import MeetingRoom from "./MeetingRoom";
import { Sessions } from "./Sessions";
import VirtualTour from "./VirtualTour";
import Redeem from "./children/Redeem";

const Exhibition = (props) => {
  const { client, history } = props;
  console.log(history);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const { language_id, code } = JSON.parse(
    localStorage.getItem("language_settings")
  );
  const user_id = localStorage.getItem("imicon_user_id");
  const { t } = useTranslation();
  const [audio] = useState(
    new Audio("https://www.iamicon2020.com/audio/coins.mp3")
  );
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isGlobalChatOpen, setIsGlobalChatOpen] = useState(false);
  const [isPersonalChatOpen, setIsPersonalChatOpen] = useState(false);
  const [isAttendeesOpen, setIsAttendeesOpen] = useState(false);
  const [isExhibitorsOpen, setIsExhibitorsOpen] = useState(false);
  const [isMyBagOpen, setIsMyBagOpen] = useState(false);
  const [isMyCertificateOpen, setIsMyCertificateOpen] = useState(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);
  const [isCoinInfoPop, setIsCoinInfoPop] = useState(false);
  const [isTickerOpen, setIsTickerOpen] = useState(false);
  const [tickerMessage, setTickerMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUserToChat, setCurrentUserToChat] = useState("");
  const [currentConversationId, setCurrentConversationId] = useState("");
  const [globalChatMessage, setGlobalChatMessage] = useState("");
  const [personalChatMessage, setPersonalChatMessage] = useState("");
  const [isCoinUIPopUp, setIsCoinUIPopUp] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(
    localStorage.getItem("imicon_user_last_login") &&
      localStorage.getItem("imicon_user_last_login") === "true"
      ? true
      : false
  );
  const [isCoinRedeemPopUp, setIsCoinRedeemPopUp] = useState(false);
  const [accessToken] = useRecoilState(accessTokenState);
  const [points, setPoints] = useRecoilState(pointsState);

  const previousConversationId = usePrevious(currentConversationId);
  const previousPoints = usePrevious(points);

  const [tourStatus, setTourStatus] = useState(false);

  const { data: rewardData } = useSubscription(SUBSCRIBE_ON_REWARD, {}, [
    accessToken,
  ]);

  const { data: playlistData } = useSubscription(
    SUBSCRIBE_ON_PLAYLIST_VIDEO,
    {
      variables: {
        active: true,
        type: "conference",
      },
    },
    [accessToken]
  );

  const [
    GET_GLOBAL_CHAT_QUERY,
    { data: globalChatData, loading: globalChatLoading },
  ] = useLazyQuery(GET_GLOBAL_CHAT, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      joinGlobalChatRoom({ globalChatId: language_id, userId: user_id });
    },
  });

  const [GET_MY_BAG_QUERY, { data: myBagData, loading: myBagLoading }] =
    useLazyQuery(GET_MY_BAG, {
      fetchPolicy: "cache-and-network",
    });

  const [
    GET_ATTENDEES_QUERY,
    { data: attendeesData, loading: attendeesLoading },
  ] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: "cache-and-network",
  });

  const [
    GET_CONVERSTION_BY_ID_QUERY,
    { data: conversationData, loading: conversationLoading },
  ] = useLazyQuery(GET_CONVERSTION_BY_ID, {
    fetchPolicy: "cache-and-network",
  });

  const [
    GET_USER_DETAILS_QUERY,
    { data: userDetailsData, loading: userDetailsLoading },
  ] = useLazyQuery(GET_USER_DETAILS, {
    fetchPolicy: "cache-and-network",
  });

  const [GET_MY_CONTACTS_QUERY, { loading: myContactLoading }] = useLazyQuery(
    GET_MY_CONTACTS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        let users = [];
        data.conversations.filter((conversation) => {
          if (conversation.user_chats[0].from !== user_id) {
            const foundUser = users.find(
              (u) => u === conversation.user_chats[0].from
            );
            if (!foundUser) {
              users.push({
                unread: conversation.unread,
                conversation_id: conversation.id,
                user_id: conversation.user_chats[0].from,
                name: `${conversation.user_chats[0].message_from.firstname} ${conversation.user_chats[0].message_from.lastname}`,
              });
            }
          }

          if (conversation.user_chats[0].to !== user_id) {
            const foundUser = users.find(
              (u) => u === conversation.user_chats[0].to
            );
            if (!foundUser) {
              users.push({
                unread: conversation.unread,
                conversation_id: conversation.id,
                user_id: conversation.user_chats[0].to,
                name: `${conversation.user_chats[0].message_to.firstname} ${conversation.user_chats[0].message_to.lastname}`,
              });
            }
          }
        });
        setUsers(users);
      },
    }
  );

  const [SEND_GLOBAL_CHAT_MUTATION, { loading: sendingGlobalChat }] =
    useMutation(STORE_GLOBAL_CHAT);

  const [SEND_USER_REWARD_TYPE] = useMutation(INSERT_USER_REWARD_TYPE);

  const [DELETE_FROM_MY_BAG_MUTATION, { loading: deletingFromMyBag }] =
    useMutation(DELETE_FROM_BY_BAG);

  const [SEND_CONNECTION_REQUEST_MUTATION] = useMutation(
    START_USER_CONVERSTION
  );

  const [SEND_PERSONAL_CHAT_MUTATION, { loading: sendingPersonalChat }] =
    useMutation(STORE_PERSONAL_CHAT);

  const [UPDATE_USER_FORM_MUTAION, { loading: sendingUserForm }] =
    useMutation(UPDATE_USER_FORM);

  const [
    INSERT_USER_CERTIFICATES_MUTAION,
    { loading: sendingUserCertificates },
  ] = useMutation(INSERT_USER_CERTIFICATES);

  const globalChatHandleChange = (e) => {
    setGlobalChatMessage(e.target.value);
  };

  const personalChatHandleChange = (e) => {
    setPersonalChatMessage(e.target.value);
  };

  const openExhibitorModal = () => {
    setIsExhibitorsOpen(true);
  };

  const openGlobalChatModal = () => {
    GET_GLOBAL_CHAT_QUERY();
    setIsGlobalChatOpen(true);
  };

  const openMyBagModal = () => {
    GET_MY_BAG_QUERY();
    setIsMyBagOpen(true);
  };

  const openMyProfileModal = () => {
    GET_USER_DETAILS_QUERY({
      variables: {
        id: user_id,
      },
    });
    setIsMyProfileOpen(true);
  };

  const openPersonalChatModal = () => {
    GET_MY_CONTACTS_QUERY();
    setIsPersonalChatOpen(true);
  };

  const onSelectConverstion = (conversation_id, user_id) => {
    GET_CONVERSTION_BY_ID_QUERY({
      variables: {
        id: conversation_id,
      },
    });

    joinConversation({ conversationId: conversation_id });
    setCurrentUserToChat(user_id);
    setCurrentConversationId(conversation_id);
  };

  const openAttendeesModal = () => {
    GET_ATTENDEES_QUERY({
      variables: {
        id: user_id,
        last_login: true,
      },
    });
    setIsAttendeesOpen(true);
  };

  const toggleSideBarMenu = () => {
    setIsSideBarOpen((sideBarStatus) => !sideBarStatus);
  };

  const closeGlobalChatModal = () => {
    setIsGlobalChatOpen(false);
  };

  const closeExhibitorModal = () => {
    setIsExhibitorsOpen(false);
  };

  const closePersonalChatModal = () => {
    setIsPersonalChatOpen(false);
  };

  const closeMyBagModal = () => {
    setIsMyBagOpen(false);
  };

  const closeMyProfileModal = () => {
    setIsMyProfileOpen(false);
  };

  const closeAttendeesModal = () => {
    setIsAttendeesOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  const intro = () => {
    setIsCoinUIPopUp(false);
    localStorage.removeItem("TourEnded");
    setTourStatus(true);
  };

  const certificate = async () => {
    setIsMyCertificateOpen(true);
    try {
      await INSERT_USER_CERTIFICATES_MUTAION();
      onInsertUserReward(CONFLICT_ON_CERTIFICATE, CERTIFICATE_REQUEST);
    } catch (err) {
      console.log(err);
    }
  };

  const sendPersonalChatMessage = async () => {
    if (personalChatMessage) {
      SEND_PERSONAL_CHAT_MUTATION({
        variables: {
          message: personalChatMessage,
          conversation_id: currentConversationId,
          to: currentUserToChat,
        },
        update: (store, { data }) => {
          sendConversation({
            ...data.insert_user_chat_one,
            conversationId: data.insert_user_chat_one.conversation_id,
          });

          const { conversations_by_pk } = store.readQuery({
            query: GET_CONVERSTION_BY_ID,
            variables: { id: currentConversationId },
          });
          const user_chats = [
            ...conversations_by_pk.user_chats,
            data.insert_user_chat_one,
          ];

          client.writeQuery({
            query: GET_CONVERSTION_BY_ID,
            variables: { id: currentConversationId },
            data: {
              conversations_by_pk: {
                user_chats,
                __typename: "conversations",
              },
            },
          });
        },
      });
      setPersonalChatMessage("");
    }
  };

  const handleKeyDownMyContact = (e) => {
    if (e.key === "Enter") {
      sendPersonalChatMessage();
    }
  };

  const handleKeyDownGlobalChat = (e) => {
    if (e.key === "Enter") {
      sendGlobalChatMessage();
    }
  };

  const sendGlobalChatMessage = () => {
    if (globalChatMessage) {
      SEND_GLOBAL_CHAT_MUTATION({
        variables: {
          message: globalChatMessage,
        },
        update: (store, { data }) => {
          sendGlobalChatMsg({
            ...data.insert_global_chat_one,
            globalChatId: language_id,
          });

          const { global_chat } = store.readQuery({ query: GET_GLOBAL_CHAT });
          const chatMessages = [...global_chat, data.insert_global_chat_one];

          store.writeQuery({
            query: GET_GLOBAL_CHAT,
            data: {
              global_chat: [...chatMessages],
            },
          });
        },
      });
      setGlobalChatMessage("");
    }
  };

  const deleteResourceFromMyBag = (resourceId) => {
    if (resourceId) {
      DELETE_FROM_MY_BAG_MUTATION({
        variables: {
          resource_id: resourceId,
          user_id,
        },
        update: (store, { data }) => {
          const cache = store.readQuery({ query: GET_MY_BAG });
          const {
            delete_user_resource_by_pk: { resource_id },
          } = data;
          const resources = cache.user_resource.filter(
            (resource) => resource.resource.id !== resource_id
          );
          store.writeQuery({
            query: GET_MY_BAG,
            data: { user_resource: resources },
          });
        },
      });
    }
  };

  const sendConnectionRequest = (toUserId) => {
    SEND_CONNECTION_REQUEST_MUTATION({
      variables: {
        to: toUserId,
        message: "hey",
      },
      update: (store, { data }) => {
        const cache = store.readQuery({
          query: GET_ATTENDEES,
          variables: { id: user_id, last_login: true },
        });
        const {
          insert_user_chat_one: { conversation_id, to },
        } = data;
        const user = cache.user.find((user) => user.id === to);

        user.user_chats_to.push({
          conversation_id: conversation_id,
          __typename: "user_chat",
        });

        store.writeQuery({
          query: GET_ATTENDEES,
          variables: { last_login: true, id: user_id },
          data: cache,
        });
      },
    });
  };

  const onSubmit = (formDetails) => {
    UPDATE_USER_FORM_MUTAION({
      variables: {
        id: user_id,
        last_login: true,
        ...formDetails,
      },
    });
    onInsertUserReward(CONFLICT_ON_PROFILE, COMPLETE_PROFILE);
    localStorage.setItem("imicon_user_last_login", true);
    setUpdateProfile(true);
  };

  const toggleCoinInfoPop = () => {
    setIsCoinInfoPop((CoinInfoPopStatus) => !CoinInfoPopStatus);
  };
  const toggleRedeemPop = () => {
    setIsCoinRedeemPopUp((isCoinRedeemPopUp) => !isCoinRedeemPopUp);
  };
  useEffect(() => {
    if (previousConversationId) {
      leaveConversation(previousConversationId);
    }
    Emitter.off("receiveConversation").on(
      "receiveConversation",
      (personalChatMessage) => {
        if (personalChatMessage.conversationId === currentConversationId) {
          const { conversations_by_pk } = client.readQuery({
            query: GET_CONVERSTION_BY_ID,
            variables: { id: currentConversationId },
          });
          const user_chats = [
            ...conversations_by_pk.user_chats,
            personalChatMessage,
          ];
          client.writeQuery({
            query: GET_CONVERSTION_BY_ID,
            variables: { id: currentConversationId },
            data: {
              conversations_by_pk: {
                user_chats,
                __typename: "conversations",
              },
            },
          });
        }
      }
    );
  }, [client, currentConversationId]);

  useEffect(() => {
    Emitter.off("notifications").on("notifications", (chatData) => {
      if (chatData.type === "message") {
        if (chatData.conversationId !== currentConversationId) {
          toast.notify(`New message from ${chatData.message_from.firstname}`);
        }
      }
    });

    Emitter.off("receiveGlobalChatMsg").on(
      "receiveGlobalChatMsg",
      (chatData) => {
        const { global_chat } = client.readQuery({ query: GET_GLOBAL_CHAT });
        const chatMessage = [...global_chat, chatData];

        client.writeQuery({
          query: GET_GLOBAL_CHAT,
          data: {
            global_chat: [...chatMessage],
          },
        });
      }
    );
  }, [client, currentConversationId]);

  useEffect(() => {
    joinSelf(user_id);
  }, []);

  useEffect(() => {
    if (playlistData?.playlist_video?.length) {
      if (playlistData.playlist_video[0].show_marquee) {
        setIsTickerOpen(true);
        setTickerMessage(playlistData.playlist_video[0].description);
      } else {
        setIsTickerOpen(false);
      }
    } else {
      setIsTickerOpen(false);
    }
  }, [playlistData]);

  useEffect(() => {
    if (rewardData) {
      const last_login =
        localStorage.getItem("imicon_user_last_login") === "true";
      if (!last_login) {
        setIsCoinUIPopUp(true);
      }

      if (previousPoints) {
        const currentWonPoints = rewardData?.user_reward[0]?.points - points;

        if (currentWonPoints > 0) {
          audio.play();
          toast.notify(
            `${t("You received")} ${currentWonPoints} ${t("points")}`,
            {
              position: "top",
              duration: 2000,
            }
          );
        } else if (currentWonPoints < 0) {
          toast.notify(
            `${t("You lost")} ${Math.abs(currentWonPoints)} ${t("points")}`,
            {
              position: "top",
              duration: 2000,
            }
          );
        }
      }
    }
    setPoints(rewardData?.user_reward[0]?.points || 0);
  }, [rewardData]);

  const steps = [
    {
      target: ".my-first-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Menu")}</h5>
          <p>{t("Click on various")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-second-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Nelson Mandela Hall")}</h5>
          <p>{t("Attend scheduled")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-third-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Entertainment Lounge")}</h5>
          <p>{t("Explore fun")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-fourth-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Exhibition Lounge")}</h5>
          <p>{t("Know more about")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-fifth-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Gallery")}</h5>
          <p>{t("Check out an exciting 360 degree")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-sixth-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Mahatma Gandhi Hall")}</h5>
          <p>{t("Enter this knowledge")}</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-seventh-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Help Desk")}</h5>
          <p>{t("Download program")}.</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".my-eighth-step",
      content: (
        <div className="stepSection_blk">
          <h5>{t("Information")}</h5>
          <p>
            {t("Learn how you can earn coins")}
            <CustIcon type="coin" size="xs" className="mx-2" />
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  const endTour = (data) => {
    const { status, type } = data;
    if (
      status === "finished" ||
      (status === "skipped" && type === "tour:end")
    ) {
      localStorage.setItem("TourEnded", true);
      setTourStatus(false);
      const last_login =
        localStorage.getItem("imicon_user_last_login") === "true";
      if (!last_login) {
        openMyProfileModal();
      }
    }
  };

  const onInsertUserReward = async (type, points_random) => {
    try {
      await SEND_USER_REWARD_TYPE({
        variables: {
          type,
          points: points_random + points,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const isProfileUpdatedOnce =
    localStorage.getItem("imicon_user_last_login") === true;

  return (
    <>
      {tourStatus ? (
        <Joyride
          steps={steps}
          floaterProps={{ disableAnimation: true }}
          continuous={true}
          styles={{
            options: {
              primaryColor: "#8f2630",
              textColor: "#000",
              zIndex: 10000,
            },
            buttonClose: {
              display: "none",
            },
          }}
          callback={endTour}
          run={tourStatus}
          spotlightPadding={15}
          locale={{
            back: `${t("Back")}`,
            close: `${t("Close")}`,
            next: `${t("Next")}`,
            last: `${t("Done")}`,
          }}
          spotlightClicks={true}
        />
      ) : null}
      <div
        className={`flex exhibitionPage transition duration-300  bg-cover bg-fixed flex-col overflow-hidde lang_${code}`}
      >
        {!isMobile && (
          <div className="gamePointCount">
            <Coin
              className=""
              points={new Intl.NumberFormat("en-IN").format(points)}
            />

            <div
              className="infoCoins my-eighth-step "
              onClick={toggleCoinInfoPop}
            >
              <CustIcon type="info" />
            </div>
          </div>
        )}

        <Switch>
          <PrivateRoute
            exact
            path="/exhibition/lobby"
            component={ExhibitionMain}
          />
          <PrivateRoute
            exact
            path="/exhibition/countries"
            component={Countries}
          />
          <PrivateRoute
            exact
            path="/exhibition/floor/:id"
            component={ExhibitionFloor}
          />
          <PrivateRoute
            exact
            path="/exhibition/floor"
            component={ExhibitionFloor}
          />
          <PrivateRoute exact path="/exhibition/games" component={Games} />
          <PrivateRoute
            exact
            path="/exhibition/games/play/:game"
            component={GamesContent}
          />
          <PrivateRoute
            exact
            path="/exhibition/floor/booth/:id"
            component={Booth}
          />

          <PrivateRoute
            exact
            path="/exhibition/virtualtour"
            component={VirtualTour}
          />

          <PrivateRoute path="/exhibition/sessions" component={Sessions} />
          <Route exact path="/exhibition/meetingroom">
            <MeetingRoom />
          </Route>
          <Route exact path="/exhibition/expolounge">
            <ExpoLounge />
          </Route>
          <Route exact path="/exhibition/informationdesk">
            <InformationDesk />
          </Route>
        </Switch>
      </div>

      {!isMobile && (
        <button
          onClick={toggleSideBarMenu}
          className="button_container dl-trigger my-first-step"
          id="toggle"
        />
      )}

      {isExhibitorsOpen && (
        <MyExhibitors
          onCloseExhibitorModal={closeExhibitorModal}
          settings={settings}
        />
      )}

      {isPersonalChatOpen && (
        <PersonalChat
          onCloseMyContact={closePersonalChatModal}
          onSelectConversation={onSelectConverstion}
          onChange={personalChatHandleChange}
          onSendPersonalChat={sendPersonalChatMessage}
          handleKeyDownMyContact={handleKeyDownMyContact}
          currentConversationId={currentConversationId}
          message={personalChatMessage}
          users={users}
          userId={user_id}
          data={conversationData}
          loading={conversationLoading}
          usersLoading={myContactLoading}
          sending={sendingPersonalChat}
        />
      )}

      {isAttendeesOpen && (
        <Attendees
          onCloseAttendeeModal={closeAttendeesModal}
          onSendConnectionRequest={sendConnectionRequest}
          data={attendeesData}
          loading={attendeesLoading}
        />
      )}

      {isSideBarOpen && (
        <SideBar
          toggleSideBarMenu={toggleSideBarMenu}
          onOpenGlobalChatModal={openGlobalChatModal}
          onOpenMyBagModal={openMyBagModal}
          onOpenMyProfileModal={openMyProfileModal}
          onOpenAttendeesModal={openAttendeesModal}
          onOpenMyContactModal={openPersonalChatModal}
          onOpenExhibitorsModal={openExhibitorModal}
          onLogOut={logout}
          onClickIntro={intro}
          onClickCertificates={certificate}
          onClickRedeemCoins={toggleRedeemPop}
        />
      )}

      {isGlobalChatOpen && (
        <GlobalChat
          onCloseGlobalChat={closeGlobalChatModal}
          onSendGlobalChat={sendGlobalChatMessage}
          handleKeyDownGlobalChat={handleKeyDownGlobalChat}
          onChange={globalChatHandleChange}
          data={globalChatData}
          message={globalChatMessage}
          loading={globalChatLoading}
          sending={sendingGlobalChat}
          userId={user_id}
        />
      )}

      {isMyBagOpen && (
        <MyBag
          onCloseMybag={closeMyBagModal}
          onDeleteMyBagItem={deleteResourceFromMyBag}
          data={myBagData}
          loading={myBagLoading}
          deleting={deletingFromMyBag}
        />
      )}

      {isMyCertificateOpen && (
        <Certificate
          onOpenMyProfileModal={openMyProfileModal}
          onCloseCertificateTab={() => setIsMyCertificateOpen(false)}
          loading={sendingUserCertificates}
        />
      )}

      {isMyProfileOpen && (
        <MyProfile
          onCloseMyProfile={closeMyProfileModal}
          data={userDetailsData}
          loading={userDetailsLoading}
          sending={sendingUserForm}
          onSubmitForm={onSubmit}
          updated={updateProfile}
        />
      )}

      {isCoinUIPopUp && (
        <CoinsPopUp
          onStartTour={intro}
          points={rewardData?.user_reward[0]?.points}
        />
      )}
      {isCoinRedeemPopUp && <Redeem onCloseRedeem={toggleRedeemPop} />}

      {isCoinInfoPop && <CoinInfoPop closeModal={toggleCoinInfoPop} />}

      {isTickerOpen && history.location.pathname !== "/exhibition/sessions" && (
        <Ticker offset={0} speed={15} height={30} mode="smooth">
          {({ index }) => (
            <>
              <h1>{tickerMessage}</h1>
            </>
          )}
        </Ticker>
      )}
    </>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const ExhibitionWithApollo = withApollo(Exhibition);
export { ExhibitionWithApollo as Exhibition };
