import React from "react";
import { Link } from "react-router-dom";
import history from "../utils/History";
import { useTranslation } from "react-i18next";
import { connect} from "../utils/Socket";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const Intro = (props) => {
  const { t } = useTranslation();
  
  const videoEndedEvent = () => {
    connect();
    history.push("/exhibition/lobby");
  };

  const navigatetoLobby = () => {
    connect();
    history.push("/exhibition/lobby");
  }

  return (
    <div className="loadTansection flex introPage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      {/* <VideoPlayer
        controls={false}
        autoplay={true}
        name="videoFrame"
        id="videoFrame"
        className="object-cover w-screen h-screen"
        src="https://dzmknw5lmz17b.cloudfront.net/videos/intro/intro.mp4"
        // poster={this.state.video.poster}
        // width="720"
        // height="420"
        // onReady={this.onPlayerReady.bind(this)}
        // onPlay={this.onVideoPlay.bind(this)}
        // onPause={this.onVideoPause.bind(this)}
        // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
        // onSeeking={this.onVideoSeeking.bind(this)}
        // onSeeked={this.onVideoSeeked.bind(this)}
        // onEnd={this.onVideoEnd.bind(this)}
      /> */}

      <video
        autoPlay
        name="videoFrame"
        className="object-cover w-screen h-screen"
        id="videoFrame"
        onEnded={videoEndedEvent}
      >
        <source
          src={`${REACT_APP_CLOUDFRONT_URL}/videos/intro/intro.mp4`}
          type="video/mp4"
        />
      </video>
      <Link
        onClick={navigatetoLobby}
        className="absolute skipBtn bg-black rounded-md px-6 py-2 text-white z-10"
      >
        {t("Skip Intro")}
      </Link>
    </div>
  );
};

export default Intro
