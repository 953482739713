import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_POLL_ANSWER_BY_QUESTION_ID } from "../../graphql/query";
import { INSERT_POLL_ANSWER } from "../../graphql/mutation";
import { useLazyQuery, useMutation } from "react-apollo";
import Loader from "./Loader";
const Poll = ({ onClosePollTab, onInsertUserReward, loading, data }) => {
  const { t } = useTranslation();

  const [pollQuestionNumber, setPollQuestionNumber] = useState(0);
  const [pollAnswer, setPollAnswer] = useState("");

  const [
    GET_POLL_ANSWER_QUERY,
    { data: pollAnswerData, loading: pollAnswerLoading },
  ] = useLazyQuery(GET_POLL_ANSWER_BY_QUESTION_ID, {
    fetchPolicy: "cache-and-network",
  });

  const [
    INSERT_POLL_ANSWER_MUTATION,
    { loading: sendingPollAnswer },
  ] = useMutation(INSERT_POLL_ANSWER);

  const handlePrevious = () => {
    setPollQuestionNumber((currentNumber) => currentNumber - 1);
    setPollAnswer("");
  };

  const handleNext = () => {
    setPollQuestionNumber((currentNumber) => currentNumber + 1);
    setPollAnswer("");
  };

  const onSelectAnswer = (e) => {
    setPollAnswer(e.target.value);
  };

  const onPollAnswer = (poll) => {
    if (pollAnswer) {
      INSERT_POLL_ANSWER_MUTATION({
        variables: {
          question_id: poll.id,
          answer: pollAnswer,
        },
        update: (store, { data: pollAnswerData }) => {
          const answer =
            data.polls[pollQuestionNumber].answer ===
            pollAnswerData.insert_poll_answer_one.answer;

          if (answer) {
            onInsertUserReward();
          }

          store.writeQuery({
            query: GET_POLL_ANSWER_BY_QUESTION_ID,
            variables: {
              question_id: pollAnswerData.insert_poll_answer_one.question_id,
            },
            data: {
              poll_answer: [pollAnswerData.insert_poll_answer_one],
            },
          });
        },
      });
    }
  };

  useEffect(() => {
    if (data?.polls?.length) {
      const { id } = data?.polls[pollQuestionNumber];
      GET_POLL_ANSWER_QUERY({
        variables: {
          question_id: id,
        },
      });
    }
  }, [pollQuestionNumber, data, GET_POLL_ANSWER_QUERY]);

  return (
    <div className="bottomModel card_poll fixed z-10">
      <div className="bg-white rounded px-5 pt-3 pb-4 sm:pb-4">
        <div className="close" onClick={onClosePollTab}>
          <span></span>
          <span></span>
        </div>
        <div className="sm:flex flex-col sm:items-start">
          <div className="card-header w-full">
            <h6>{t("Quiz")}</h6>
          </div>
          <div className="card-body">
            {!data && loading ? (
              <Loader />
            ) : (
                data && (
                  <>
                    <div className="question">
                      <p className="mb-2 d-flex color-dark">
                        <span className="d-inline-block que-no mr-3">
                          {pollQuestionNumber + 1}
                        </span>
                        {data?.polls[pollQuestionNumber]?.question}
                      </p>
                    </div>
                    {data?.polls[pollQuestionNumber]?.options.map(
                      (option, index) => (
                        <div
                          key={index}
                          className={`radiobtn-custom ${pollAnswerData &&
                            (pollAnswerData.poll_answer.length
                              ? option === pollAnswerData.poll_answer[0].answer
                                ? data.polls[pollQuestionNumber].answer ===
                                  pollAnswerData.poll_answer[0].answer
                                  ? "correct"
                                  : data.polls[pollQuestionNumber].answer === option
                                    ? "correct"
                                    : "incorrect"
                                : data.polls[pollQuestionNumber].answer === option
                                  ? "correct"
                                  : null
                              : null)
                            }`}
                        >
                          <div className="form-check">
                            <input
                              key={`${option}_${index}`}
                              className="form-check-input"
                              type="radio"
                              name="Quiz"
                              disabled={pollAnswerData?.poll_answer.length}
                              defaultValue={option}
                              onChange={onSelectAnswer}
                              id={option}
                            />
                            <label className="" htmlFor={option}>
                              {option}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )
              )}
          </div>
          <div className="card-footer flex justify-between w-full items-center">
            <div className="prevBtn">
              <button
                onClick={handlePrevious}
                style={{ display: pollQuestionNumber === 0 && "none" }}
                className="flex"
              >
                <img src={require("../../images/send.svg")} alt="" />
              </button>
            </div>
            <div className="submit">
              <button
                disabled={
                  pollAnswerLoading ||
                  (pollAnswerData && pollAnswerData?.poll_answer.length)
                }
                className="btn btn-submit"
                onClick={() => onPollAnswer(data.polls[pollQuestionNumber])}
              >
                {pollAnswerLoading
                  ? t("Loading")
                  : sendingPollAnswer
                    ? t("Sending")
                    : t("Submit")}
              </button>
            </div>
            <div className="nextBtn">
              <button
                onClick={handleNext}
                style={{
                  display:
                    pollQuestionNumber === data?.polls.length - 1 && "none",
                }}
                className="flex"
              >
                <img
                  src={require("../../images/send.svg")}
                  className="transform rotate-180"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poll;
