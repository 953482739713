import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const GlobalChat = ({
  onCloseGlobalChat,
  onSendGlobalChat,
  onChange,
  handleKeyDownGlobalChat,
  loading,
  sending,
  message,
  userId,
  data,
}) => {
  const globalChatEndRef = useRef(null);

  const { t } = useTranslation();

  const scrollToBottom = () => {
    globalChatEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      scrollToBottom();
    }
  }, [data]);

  return (
    <div className="fixed modelLiveChat model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50" onClick={onCloseGlobalChat}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseGlobalChat}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div class="popup-header border-b border-solid w-full">
                <h3>{t("Live Chat")}</h3>
              </div>
              <div className="popup-body w-full">
                <div className="chatSection_blk">
                  <ul className="chatList">
                    {!data && loading ? (
                      <Loader />
                    ) : (
                        data &&
                        data.global_chat.map((chat, index) => (
                          <li
                            key={index}
                            className={chat.by === userId ? "me" : ""}
                          >
                            <div className="user">
                              <img src={chat.user.photo} alt="profile" />
                            </div>
                            <div className="content" key={index}>
                              <div className="userName">
                                {chat.by === userId
                                  ? "me"
                                  : `${chat.user.firstname} ${chat.user.lastname}`}
                              </div>
                              <div className="message">{chat.message}</div>
                            </div>
                          </li>
                        ))
                      )}
                  </ul>
                  <div ref={globalChatEndRef} />
                </div>

                <div className="card-footer card-footer-que border-t">
                  <div className="flex justify-between items-center p-3">
                    <img alt="Add" src={require("../../images/add.svg")} />
                    <input
                      key="globalChatInput"
                      type="text"
                      onChange={onChange}
                      onKeyDown={handleKeyDownGlobalChat}
                      value={message}
                      className="msg-input w-full px-3"
                      placeholder={t("Type your message")}
                    />
                    <button onClick={onSendGlobalChat} className="btn-submit">
                      {sending ? `${t("Sending")}` : `${t("Send")}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalChat;
