import React, { useState } from "react";
import { GET_BOOTH_LIST_BY_COUNTRY } from "../../graphql/query";
import { useQuery } from "react-apollo";
import history from "../../utils/History";
import SrcnLoader from "./SrcnLoader";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const EnglishFloor = ({ match }) => {

    const { id } = match.params;
    const [videoLoader,setVideoLoader] = useState(true);
    const {  code } = JSON.parse(
        localStorage.getItem("language_settings")
      );

      const onFloorLoad = () => {
        setVideoLoader(false);
      } 

    const { data, loading } = useQuery(GET_BOOTH_LIST_BY_COUNTRY, {
        variables: {
            id: id
        },
        fetchPolicy: "cache-and-network"
    });

    const navigateToBooth = (id) => {
        history.push(`/exhibition/floor/booth/${id}`)
    }

    console.log('Data English -> ', data)


    return (
        <div className="loadTansection flex exhibitionFloorPage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
            <div className="exhibitionFloorImg w-screen">
                {videoLoader &&  <SrcnLoader />}
                {(data && <video
                    className="object-cover w-screen h-screen"
                    width="100%"
                    onLoadedData={onFloorLoad}
                    autoPlay
                    loop
                    muted
                    height="100%"
                    src={`${REACT_APP_CLOUDFRONT_URL}/floor/${code}/floor_${data?.country_by_pk?.country.toLowerCase()}.mp4`}
                    controls={false}
                ></video>)}
                {/* <video
                    className="object-cover w-screen h-screen"
                    width="100%"
                    autoPlay
                    loop
                    muted
                    height="100%"
                    src="https://expo.cadila.com.s3.ap-south-1.amazonaws.com/floor/ru/floor_ru.mp4"
                    controls={false}
                ></video> */}
                <div className={`boothContainer boothFloor_${data?.country_by_pk?.booths.length}`}>
                    {
                        data && (
                            data.country_by_pk.booths.map((booth) => (
                                <span key={booth.id} onClick={() => navigateToBooth(booth.id)} className={`hint boothPoint_${booth.order}`} rel="Sessions" />
                            ))
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default EnglishFloor;