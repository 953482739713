import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const {
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_KEY,
  REACT_APP_NODE_ENV,
} = process.env;

const user_id = localStorage.getItem("imicon_user_id");

const analyticsInit = () => {
  // Enable debug mode on the local development environment
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_KEY);
};

export function sendEvent(payload) {
  ReactGA.event(payload);
}

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.set({ userId: user_id });
  ReactGA.pageview(path);
};

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!["development", "local"].includes(REACT_APP_NODE_ENV)) {
      analyticsInit();
    }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location, user_id]);
};
