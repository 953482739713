import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import "react-tabs/style/react-tabs.css";
import Loader from "./Loader";

const PersonalChat = ({
  onCloseMyContact,
  currentConversationId,
  onSelectConversation,
  onSendPersonalChat,
  onChange,
  loading,
  sending,
  usersLoading,
  data,
  message,
  users,
  userId,
  handleKeyDownMyContact,
}) => {
  const personalChatEndRef = useRef(null);
  const { t } = useTranslation();

  const scrollToBottom = () => {
    personalChatEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (data) {
      scrollToBottom();
    }
  }, [data]);

  useEffect(() => {
    if (users.length) {
      onSelectConversation(users[0].conversation_id, users[0].user_id);
    }
  }, [users]);

  return (
    <div className="fixed modelLiveChat model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50" onClick={onCloseMyContact}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseMyContact}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div class="popup-header border-b border-solid w-full">
                <h3>{t("Live Chat")}</h3>
              </div>
              <div className="popup-body w-full">
                <div className="flex">
                  <div className="personalChat">
                    <div className="chatSection_blk">
                      <ul className="chatList">
                        {!data && loading ? (
                          <Loader />
                        ) : (
                            data &&
                            data.conversations_by_pk.user_chats.map(
                              (chat, index) => (
                                <li
                                  key={index}
                                  className={chat.from === userId ? "me" : ""}
                                >
                                  <div className="user">
                                    <img
                                      src={chat.message_from.photo}
                                      alt={chat.id}
                                    />
                                  </div>
                                  <div className="content" key={index}>
                                    <div className="userName">
                                      {chat.from === userId
                                        ? "me"
                                        : chat.message_from.firstname ||
                                        chat.message_to.firstname}
                                    </div>
                                    <div className="message">{chat.message}</div>
                                  </div>
                                </li>
                              )
                            )
                          )}
                        {/* {data ? <Loader /> : null} */}
                        {/* {console.log({!data && loading ? (null) : (data)})} */}
                      </ul>
                      <div ref={personalChatEndRef} />
                    </div>
                    <div className="card-footer card-footer-que border-t">
                      <div className="flex justify-between items-center p-3">
                        <img alt="Add" src={require("../../images/add.svg")} />
                        <input
                          type="text"
                          onChange={onChange}
                          onKeyDown={handleKeyDownMyContact}
                          value={message}
                          className="msg-input text-black w-full px-3 focus:shadow-none"
                          placeholder={t("Type your message")}
                        />
                        <button
                          onClick={onSendPersonalChat}
                          className="btn-submit"
                        >
                          {sending ? `${t("Sending")}` : `${t("Send")}`}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="my-contact-section">
                    <h5 className="title-sm px-4">{t("Contacts")}</h5>
                    <ul className="my-contacts-online">
                      {!users.length && usersLoading ? (
                        <Loader />
                      ) : (
                          users.map((user, index) => (
                            <li
                              className={
                                user.conversation_id === currentConversationId
                                  ? "active"
                                  : ""
                              }
                              key={index}
                              onClick={() =>
                                onSelectConversation(
                                  user.conversation_id,
                                  user.user_id
                                )
                              }
                            >
                              {user.unread && <div>{t("Unread")}</div>}
                              {user.name}
                            </li>
                          ))
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalChat;
