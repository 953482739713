import React from "react";
import { useTranslation } from "react-i18next";
import { pointsState } from "../../atoms/atoms";
import { useRecoilState } from "recoil";
import toast from "toasted-notes";
import Coin from "./Coin";
import { INSERT_USER_REDEEM_REQUEST } from "../../graphql/mutation";
import { GET_SETTINGS } from "../../graphql/query";
import { useMutation, useQuery } from "react-apollo";
import CustIcon from "./Svgs";

const Redeem = ({ onCloseRedeem }) => {
  const { t } = useTranslation();
  const [points] = useRecoilState(pointsState);
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  const {
    loading: settingsLoading,
    data: settingsData,
    errors: settingsErrors,
  } = useQuery(GET_SETTINGS, {
    variables: {
      type: "redeem",
    },
  });
  const [INSERT_REDEEM_REQUEST_MUTATION, { loading }] = useMutation(
    INSERT_USER_REDEEM_REQUEST
  );

  const onRedeem = async () => {
    try {
      if (!settingsData?.settings[0]?.status) {
        toast.notify(
          `Please visit this page on 28th of november to redeem your points`
        );
        return;
      }
      await INSERT_REDEEM_REQUEST_MUTATION();
      toast.notify("Your request to reedem is accepted");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="coinPopup modalRedeem">
      <div className="coinOverlay" onClick={onCloseRedeem}></div>
      <div className="coinModal">
        <div className="coinModalHeader flex justify-between">
          <h3>{t("Rewards")}</h3>
          <Coin
            className=""
            points={new Intl.NumberFormat("en-IN").format(points)}
          />
        </div>

        <div className="coinModalBody">
          <div className="redeemCenter py-4">
            <div className="flex">
              <div className="mx-3 w-1/5 relative">
                <img src={require(`../../images/offer-1.png`)} alt="" />
                {/* {code } */}
                <div className="redeemrange">
                  <CustIcon type="coin" size="xs" />{" "}
                  <span className="range">
                    {code === "ru" ? "5000 to 9999" : "10000 to 29999"}
                  </span>
                </div>
              </div>
              <div className="mx-3 w-1/5 relative">
                <img src={require(`../../images/offer-2.png`)} alt="" />
                {/* {code } */}
                <div className="redeemrange">
                  <CustIcon type="coin" size="xs" />{" "}
                  <span className="range">
                    {code === "ru" ? "10000 to 19999" : "30000 to 44999"}
                  </span>
                </div>
              </div>
              <div className="mx-3 w-1/5 relative">
                <img src={require(`../../images/offer-3.png`)} alt="" />
                {/* {code } */}
                <div className="redeemrange">
                  <CustIcon type="coin" size="xs" />{" "}
                  <span className="range">
                    {code === "ru" ? "20000 to 29999" : "45000 to 59999"}
                  </span>
                </div>
              </div>
              <div className="mx-3 w-1/5 relative">
                <img src={require(`../../images/offer-4.png`)} alt="" />
                {/* {code } */}
                <div className="redeemrange">
                  <CustIcon type="coin" size="xs" />{" "}
                  <span className="range">
                    {code === "ru" ? "30000 to 39999" : "60000 to 74999"}
                  </span>
                </div>
              </div>
              <div className="mx-3 w-1/5 relative">
                <img src={require(`../../images/offer-5.png`)} alt="" />
                {/* {code } */}
                <div className="redeemrange">
                  <CustIcon type="coin" size="xs" />{" "}
                  <span className="range">
                    {">"}
                    {code === "ru" ? "40000" : "75000"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="coinModalFooter flex flex-row items-center justify-center">
            <div className="earnViewBtn">
              <button
                className="btn-submit mr-3"
                onClick={onRedeem}
                disabled={settingsLoading}
              >
                {!loading ? t("Redeem") : t("Sending")}
              </button>
              <button className="btn-cancel" onClick={onCloseRedeem}>
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redeem;
