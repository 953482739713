import React from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import CustIcon from "./Svgs";

const CoinInfoPop = ({ closeModal }) => {
  const { t } = useTranslation();
  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  return (
    <div className="fixed modelpopInformation model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={closeModal}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={closeModal}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div className="popup-header border-b mb-3 w-full">
                <h3>{t("Information")}</h3>
              </div>
              <div className="popup-body w-full">
                <div className="bottom-0 flex popInformation sticky">
                  <ul className="list">
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">{t("Tell us more about you!")}</div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">
                        {t("Watch entire sessions live in Nelson Mandela Hall")}
                      </div>
                    </li>
                    {code === "en" && (
                      <li className="flex items-start pb-3">
                        <div className="icon">
                          <CustIcon type="coin" size="xs" />
                        </div>
                        <div className="pl-3">
                          {t("Watch sessions in Mahatma Gandhi Hall")}
                        </div>
                      </li>
                    )}
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">
                        {t("Watch videos in the Lobby and hit the jackpot!")}
                      </div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">
                        {t("Watch brand videos in the Exhibit Lounge")}
                      </div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">
                        {t("Take a 360 tour of the Manufacturing plant")}
                      </div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">{t("How was your experience")}</div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">{t("Feeling lucky")}</div>
                    </li>
                    <li className="flex items-start pb-3">
                      <div className="icon">
                        <CustIcon type="coin" size="xs" />
                      </div>
                      <div className="pl-3">{t("Know more about iamicon")}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinInfoPop;
