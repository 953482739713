export const FIRST_TIME_LOGIN = 500;
export const FEED_BACK_UPDATE = 2000;
export const COMPLETE_PROFILE = 5000;
export const VIRTUAL_TOUR = 1500;
export const SHRI_MODI_TOUR = 10000;
export const CADILA_CORPORATE_TOUR = 5000;
export const CERTIFICATE_REQUEST = 1500;
export const SESSION_POLL_REWARD = 1500;
export const BOOTH_POLL_REWARD = 1000;
export const PANO_VIDEO = 2000;
export const RUSSIA_VIDEO = 10000;
export const KENYA_VIDEO = 10000;
export const SRILANKA_VIDEO = 10000;

/** CONFLICTS **/
export const CONFLICT_ON_PROFILE = "PROFILE";
export const CONFLICT_ON_FEEDBACK = "FEEDBACK";
export const CONFLICT_ON_CERTIFICATE = "CERTIFICATE";
export const CONFLICT_ON_PANO_VIDEO = "PANO_VIDEO";
export const CONFLICT_ON_CADILA_CORPORATE_VIDEO = "CADILA_CORPORATE_VIDEO";
export const CONFLICT_ON_RUSSIA_VIDEO = "GALLERY_RUSSIA_VIDEO";
export const CONFLICT_ON_KENYA_VIDEO = "GALLERY_KENYA_VIDEO";
export const CONFLICT_ON_SRILANKA_VIDEO = "GALLERY_SRILANKA_VIDEO";

/** MESSAGES */
export const WIN_MESSAGE = "You received %s coins";

/** LINKS **/
export const FACEBOOK_LINK = "https://www.facebook.com/officialcadilapharma";
export const TWITTER_LINK = "https://twitter.com/Cadila_Pharma";
export const LINKEDIN_LINK =
  "https://www.linkedin.com/company/cadila-pharmaceuticals/";
