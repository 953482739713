import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { pointsState } from "../atoms/atoms";
import history from "../utils/History";
import SrcnLoader from "./children/SrcnLoader";
import {
  BOOTH_POLL_REWARD,
  LINKEDIN_LINK,
  TWITTER_LINK,
  FACEBOOK_LINK,
} from "../constants/constants";

import {
  ADD_TO_RESOURCE,
  INSERT_USER_REWARD,
  INSERT_USER_REWARD_RESOURCE,
  INSERT_USER_VISITS,
} from "../graphql/mutation";
import {
  GET_BOOTH_DETAILS_BY_ID,
  GET_BOOTH_POLL_BY_ID,
} from "../graphql/query";
import Loader from "./children/Loader";
import Poll from "./children/Poll";
import CustIcon from "./children/Svgs";
const { REACT_APP_CLOUDFRONT_URL } = process.env;

const Booth = ({ match }) => {
  const { t } = useTranslation();
  const [modalsalesInfo, setsalesInfo] = useState(false);
  const [modalBrochures, setBrochures] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [videoLoader, setVideoLoader] = useState(true);
  const [isPollOpen, setIsPollOpen] = useState(false);
  const [card, setCard] = useState({});
  const [brochure, setBrochure] = useState("");
  const [video, setVideo] = useState("");
  const [points] = useRecoilState(pointsState);
  const [isCalled, setIsCalled] = useState(false);

  const { code } = JSON.parse(localStorage.getItem("language_settings"));

  const { id } = match.params;

  const { data: boothData, loading: boothLoading } = useQuery(
    GET_BOOTH_DETAILS_BY_ID,
    {
      variables: {
        id: id,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [GET_BOOTH_POLL_BY_ID_QUERY, { data: pollData, loading: pollLoading }] =
    useLazyQuery(GET_BOOTH_POLL_BY_ID, {
      fetchPolicy: "cache-and-network",
    });

  const [storeToMyResource, { loading: loadingMyResource }] =
    useMutation(ADD_TO_RESOURCE);

  const [SEND_USER_REWARD] = useMutation(INSERT_USER_REWARD);

  const [insertUserRewardResource] = useMutation(INSERT_USER_REWARD_RESOURCE);
  const [insertUserVisit] = useMutation(INSERT_USER_VISITS);

  const onFloorLoad = () => {
    setVideoLoader(false);
  };

  useEffect(() => {
    if (boothData) {
      setCard(
        boothData.booth_by_pk.resources.find(
          (resource) => resource.resource_type.name === "card"
        )
      );
      setVideo(
        boothData.booth_by_pk.resources.find(
          (resource) => resource.resource_type.name === "video"
        )
      );
      setBrochure(
        boothData.booth_by_pk.resources.find(
          (resource) => resource.resource_type.name === "brochure"
        )
      );
      insertUserVisit({
        variables: {
          booth_id: boothData?.booth_by_pk?.id,
          type: `Booth_${boothData?.booth_by_pk?.name}`,
        },
      });
    }
  }, [boothData]);
  const handleAddToBag = async (resource_id, pointsArg) => {
    try {
      await insertUserRewardResource({
        variables: {
          resource_id,
          points: points + pointsArg,
        },
      });
    } catch (error) {
      console.log(error);
    }
    storeToMyResource({
      variables: {
        resource_id,
      },
    });
  };

  const openPollTab = () => {
    if (boothData.booth_by_pk) {
      GET_BOOTH_POLL_BY_ID_QUERY({
        variables: {
          id: id,
        },
      });
      setIsPollOpen(true);
    }
  };

  const closePollTab = () => {
    setIsPollOpen(false);
  };

  const onInsertUserReward = async () => {
    try {
      await SEND_USER_REWARD({
        variables: {
          points: BOOTH_POLL_REWARD + points,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onVideoEnded = async () => {
    if (!isCalled) {
      setIsCalled(true);
      try {
        await insertUserRewardResource({
          variables: {
            points: points + video.points,
            resource_id: video.id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="loadTansection boothPage h-screen flex justify-center items-start overflow-hidden">
      {videoLoader && <SrcnLoader />}
      <Link
        onClick={() => {
          history.goBack();
        }}
        className="btn-back"
      >
        <CustIcon type="arrow-left" className="mr-2" /> {t("Back")}
      </Link>
      <div className="boothImg">
        <video
          className="object-cover w-screen h-screen"
          width="100%"
          autoPlay
          loop
          muted
          onLoadedData={onFloorLoad}
          height="100%"
          src={`${REACT_APP_CLOUDFRONT_URL}/booths/${code}/${boothData?.booth_by_pk?.country?.country.toLowerCase()}_${
            boothData?.booth_by_pk?.order
          }.mp4`}
          controls={false}
        ></video>
      </div>
      <div
        className={`boothContainer booth${boothData?.booth_by_pk?.position}`}
      >
        <span
          className="boothHint salesInfo"
          rel={t("Sales Info")}
          onClick={() => setsalesInfo(true)}
        >
          <CustIcon type="coin" size="sm" />
        </span>
        <span
          className="boothHint brochures"
          rel={t("Brochures")}
          onClick={() => setBrochures(true)}
        >
          <CustIcon type="coin" size="sm" />
        </span>
        <span
          className="boothHint videoInfo"
          onClick={() => setModalVideo(true)}
          rel={t("Video Info")}
        >
          <CustIcon type="coin" size="sm" />
        </span>
      </div>

      <div className="rightAction">
        <ul>
          <li>
            <button
              onClick={openPollTab}
              disabled={boothLoading || !boothData.booth_by_pk}
              className="polllink justify-between"
            >
              {t("Quiz")}
              <CustIcon type="coin" size="xs" className="ml-2" />
            </button>
          </li>
          <li>
            <a href={FACEBOOK_LINK} target="blank">
              {t("Facebook")}
              <CustIcon type="facebook" className="social" />
            </a>
          </li>
          <li>
            <a href={TWITTER_LINK} target="blank">
              {t("Twitter")}
              <CustIcon type="twitter" className="social" />
            </a>
          </li>
          <li>
            <a href={LINKEDIN_LINK} target="blank">
              {t("LinkedIn")}
              <CustIcon type="linkedin" className="social" />
            </a>
          </li>
        </ul>
      </div>

      {isPollOpen && (
        <Poll
          onClosePollTab={closePollTab}
          onInsertUserReward={onInsertUserReward}
          data={pollData?.booth_by_pk}
          loading={pollLoading}
        />
      )}

      {modalsalesInfo && (
        <div className="fixed salesInfoModel model_blk z-10 inset-0 overflow-y-auto text-black">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={() => setsalesInfo(false)}
              ></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div
              className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {boothLoading ? (
                <Loader />
              ) : (
                boothData && (
                  <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
                    <div className="close" onClick={() => setsalesInfo(false)}>
                      <span></span>
                      <span></span>
                    </div>
                    <div class="popup-header border-b border-solid w-full">
                      <div className="flex justify-between items-center mb-2 w-full">
                        <h3>{card.name}</h3>
                        <button
                          className="btn-submit -mt-4"
                          onClick={() => handleAddToBag(card.id, card.points)}
                        >
                          {loadingMyResource
                            ? `${t("Added")}`
                            : `${t("Add to Cart")}`}{" "}
                          <CustIcon type="coin" size="xs" className="ml-2" />
                        </button>
                        <h3 className="invisible">{t("Visiting Card")}</h3>
                      </div>
                    </div>
                    <div className="popup-body w-full">
                      <img alt="card" src={card.url} />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
      {modalBrochures && (
        <div className="fixed brochuresModel model_blk z-10 inset-0 overflow-y-auto text-black">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={() => setBrochures(false)}
              ></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div
              className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {boothLoading ? (
                <Loader />
              ) : (
                boothData && (
                  <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
                    <div className="close" onClick={() => setBrochures(false)}>
                      <span></span>
                      <span></span>
                    </div>
                    <div className="sm:flex flex-col sm:items-start">
                      <div class="popup-header border-b border-solid w-full">
                        <div className="flex justify-between items-center mb-2 w-full">
                          <h3>{brochure.name}</h3>
                          <button
                            className="btn-submit -mt-3"
                            onClick={() =>
                              handleAddToBag(brochure.id, brochure.points)
                            }
                          >
                            {loadingMyResource
                              ? `${t("Added")}`
                              : `${t("Add to Cart")}`}
                            <CustIcon type="coin" size="xs" className="ml-2" />
                          </button>
                          <h3 className="invisible">{t("Brochure")}</h3>
                        </div>
                      </div>
                      <div className="popup-body w-full">
                        <iframe
                          title="Brochure"
                          src={brochure.url}
                          className="w-full "
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
      {modalVideo && (
        <div className="fixed boothvideoModel model_blk z-10 inset-0 overflow-y-auto text-black">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={() => setModalVideo(false)}
              ></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div
              className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-4xl sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {boothLoading ? (
                <Loader />
              ) : (
                boothData && (
                  <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
                    <div className="close" onClick={() => setModalVideo(false)}>
                      <span></span>
                      <span></span>
                    </div>
                    <div className="sm:flex flex-col sm:items-start">
                      <div class="popup-header border-b border-solid w-full">
                        <h3>{video.name}</h3>
                      </div>
                      <div className="popup-body w-full">
                        <div className="boothvideo">
                          <video
                            onEnded={onVideoEnded}
                            width="100%"
                            autoPlay
                            height="100%"
                            src={video.url}
                            controls={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Booth;
