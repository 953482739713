import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { accessTokenState} from "../atoms/atoms";
import { useRecoilState } from "recoil";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [accessToken,] = useRecoilState(accessTokenState);

    return <Route {...rest} render={(props) => {
        localStorage.setItem('location', rest.location.pathname)
        return rest.location.pathname === "/home" || rest.location.pathname === "/"
            ? <Component {...props} />
            : (
                localStorage.getItem('imicon_access_token') || accessToken
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: '/login',
                        state: { from: props.location.pathname }
                    }} />
            )
    }} />
}

export default PrivateRoute;