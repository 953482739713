import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/History";
import { RecoilRoot , useRecoilTransactionObserver_UNSTABLE} from "recoil";
import { ApolloProvider } from 'react-apollo'
import Client from "./apolloClient";
import { accessTokenState, pointsState } from "./atoms/atoms";

function DebugObserver() {
  useRecoilTransactionObserver_UNSTABLE(({snapshot}) => {
    localStorage.setItem("points",snapshot.getLoadable(pointsState).contents)
    localStorage.setItem("imicon_access_token",snapshot.getLoadable(accessTokenState).contents)
  });
  return null;
}

const initializeState = ({ set }) => {
  set({ key: "pointsState" }, parseFloat(localStorage.getItem("points")));
  set({ key: "accessTokenState" }, localStorage.getItem("imicon_access_token"));
}

ReactDOM.render(
  <RecoilRoot initializeState={initializeState}>
    <DebugObserver/>
    <ApolloProvider client={Client}>
      <Router history={history}>
        <App />
      </Router>
    </ApolloProvider>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
