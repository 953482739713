import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const QA = ({
  onCloseQuestionTab,
  onSubmitQuestion,
  onChange,
  message,
  sending,
  loading,
  data,
}) => {
  const questionEndRef = useRef(null);

  const scrollToBottom = () => {
    questionEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      scrollToBottom();
    }
  }, [data]);

  return (
    <div className="chat askaQuestion bottomModel fixed z-10" id="seminarChat">
      <div className=" card card-poll card-chat d-flex flex-column rounded-t-lg bg-white card-absolute-bottom">
        <div className="card-header flex items-center justify-between py-3 px-3 mb-0">
          <h3 className="card-title">{t("Ask a Question")}</h3>
          <div onClick={onCloseQuestionTab} className="close-div">
            <img
              alt="close"
              src={require("../../images/close.svg")}
              width="12"
              height="12"
            />
          </div>
        </div>
        <div className="card-body flex-grow-1">
          <div className="chatSection_blk">
            <ul className="chatList">
              {!data && loading ? (
                <Loader />
              ) : (
                data &&
                data.conference_by_pk.qas.map((q, index) => (
                  <li key={index}>
                    <div className="question">
                      <div className="content">
                        <div className="message">{q.question}</div>
                      </div>
                    </div>
                    {q.answer && (
                      <div className="answer">
                        <div className="content">
                          <div className="message">{q.answer}</div>
                        </div>
                      </div>
                    )}
                  </li>
                ))
              )}
            </ul>
            <div ref={questionEndRef} />
          </div>
        </div>
        <div className="card-footer card-footer-que border-t">
          <div className="flex justify-between items-center p-3">
            <input
              type="text"
              onChange={onChange}
              className="msg-input w-full px-3"
              placeholder={t("Type your question")}
              value={message}
            />
            <button onClick={onSubmitQuestion} className="btn-submit">
              {sending ? `${t("Sending")}` : `${t("Submit")}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QA;
