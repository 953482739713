import React from "react";
import CustIcon from "./Svgs";

const SrcnLoader = () => {
    return (
        <div className="srcnLoader">
            <CustIcon type="loader" />
        </div>
    );
};

export default SrcnLoader;
