import React from 'react';
function Registration() {
  // const submitForm = (e) =>{ 
  //   this.props.history.push('/url');
  //   return <Redirect push to={{
  //     pathname: '/result'
  //     state: {data: data.toString()}
  //   }}
  //   />
  // }
  return (
    <div className="loadTansection flex signInPage bg-cover bg-fixed flex-col min-h-screen overflow-hidden">
      {/* <video name="videoFrame" className="object-cover w-screen h-screen" id="videoFrame" src="https://djyjlsv34fx05.cloudfront.net/intellistreamers/intelliexpo/login1.mp4" playsInLine ></video> */}
      <video autoPlay  name="videoFrame" className="object-cover w-screen h-screen" id="videoFrame">
        <source src="https://djyjlsv34fx05.cloudfront.net/intellistreamers/intelliexpo/login1.mp4" type="video/mp4" />
      </video>
      <div className="authenticationSec flex-grow flex flex-col items-center justify-between">
         <div className="regLogo"> 
          <img alt="" src={require('../images/logo.png')} />
         </div>
        <div className="loginform w-full px-8">
          <h3 className="mt-4 font-bold">Visitor’s Registration</h3>
          <div className="max-w-xs pt-3">
            <form className="">
              <div className="mb-3">
                <input className="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base h-3/4" id="email" type="email" placeholder="email" />
              </div>
              <div className="mb-3">
                <input className="appearance-none border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base h-3/4" id="password" type="password" placeholder="Password" />
              </div>
              <div className="mb-3">
                <input className="appearance-none border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base h-3/4" id="badgeNumber" type="text" placeholder="Badge Number" />
              </div>
              <div className="flex items-center justify-between">
                <a href="intro" className="bg-primary-200 hover:bg-primary-300 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button"> Submit </a>
                {/* <button className="bg-primary-200 hover:bg-primary-300 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button"> Submit </button> */}
              </div>
            </form>
            <a href="login" className="flex  text-left text-white text-xl mt-8 hover:underline"> Sign In </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;

// {require('../images/old/news-author-04.jpg')}