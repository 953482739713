import React, { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  withApollo,
  useSubscription,
} from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { pointsState } from "../atoms/atoms";
import { SESSION_POLL_REWARD } from "../constants/constants";
import VideoPlayer from "react-video-js-player";
import {
  ADD_TO_RESOURCE,
  INSERT_USER_REWARD,
  INSERT_USER_REWARD_RESOURCE,
  INSERT_USER_REWARD_VIDEO,
  STORE_CONF_CHAT,
  STORE_CONF_QUESTION,
  INSERT_USER_VISITS,
} from "../graphql/mutation";
import {
  GET_CONFERENCE,
  GET_CONFERENCE_CHAT,
  GET_CONFERENCE_POLL,
  GET_CONFERENCE_QA,
} from "../graphql/query";
import Emitter from "../utils/Emmiter";
import { joinConfRoom, sendConferenceMsg } from "../utils/Socket";
import Agenda from "./children/Agenda";
import ConferenceChat from "./children/ConferenceChat";
import Loader from "./children/Loader";
import Poll from "./children/Poll";
import QA from "./children/QA";
import CustIcon from "./children/Svgs";
import { SUBSCRIBE_ON_PLAYLIST_QA } from "../graphql/subscription";
import toast from "toasted-notes";

const { REACT_APP_CLOUDFRONT_URL } = process.env;

const Sessions = (props) => {
  const { client } = props;
  const { t } = useTranslation();
  const { code } = JSON.parse(localStorage.getItem("language_settings"));
  let isCalled = false;
  const [isAgendaOpen, setIsAgendaOpen] = useState(false);
  const [isPollOpen, setIsPollOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  // const [isCalled, setIsCalled] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [qaMessage, setQaMessage] = useState("");
  const [pollQa, setPollQa] = useState(false);
  const [player, setPlayer] = useState(null);
  const [points] = useRecoilState(pointsState);

  const [SEND_CONFERENCE_CHAT_MUTATION, { loading: sendingChatMessage }] =
    useMutation(STORE_CONF_CHAT);

  const [SEND_CONFERENCE_QA_MUTATION, { loading: sendingQA }] =
    useMutation(STORE_CONF_QUESTION);

  const [SEND_USER_REWARD_FOR_VIDEO] = useMutation(INSERT_USER_REWARD_VIDEO);
  const [SEND_USER_REWARD] = useMutation(INSERT_USER_REWARD);
  const [storeToMyResource, { loading: loadingMeetingResource }] =
    useMutation(ADD_TO_RESOURCE);
  const [insertUserRewardResource] = useMutation(INSERT_USER_REWARD_RESOURCE);
  const [insertUserVisit] = useMutation(INSERT_USER_VISITS);

  const { data: confData, loading } = useQuery(GET_CONFERENCE, {
    variables: {
      type: "conference",
    },
    fetchPolicy: "cache-and-network",
  });

  const [GET_CONFERENCE_CHAT_QUERY, { data: chatData, loading: chatLoading }] =
    useLazyQuery(GET_CONFERENCE_CHAT, {
      fetchPolicy: "cache-and-network",
    });

  const [GET_CONFERENCE_QA_QUERY, { data: qaData, loading: qaLoading }] =
    useLazyQuery(GET_CONFERENCE_QA, {
      fetchPolicy: "cache-and-network",
    });

  const [GET_CONFERENCE_POLL_QUERY, { data: pollData, loading: pollLoading }] =
    useLazyQuery(GET_CONFERENCE_POLL, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (confData && confData.conference.length) {
      joinConfRoom({ confId: confData.conference[0].id });

      insertUserVisit({
        variables: {
          conference_id: confData.conference[0].id,
          type: "Session",
        },
      });
    }
  }, [confData]);

  const { data: playlistQaData } = useSubscription(
    SUBSCRIBE_ON_PLAYLIST_QA,
    {
      variables: {
        id: confData?.conference[0]?.id,
      },
    },
    []
  );

  useEffect(() => {
    if (
      playlistQaData &&
      playlistQaData?.conference_by_pk?.playlists[0]?.playlist_videos[0]?.polls
        .length > 0
    ) {
      toast.notify(
        `${t("You have poll questions for this particular event")}`,
        {
          position: "top",
          duration: 2000,
        }
      );
      setPollQa(true);
    }
  }, [playlistQaData]);
  useEffect(() => {
    Emitter.off("receiveConferenceMsg").on(
      "receiveConferenceMsg",
      (confChatMessage) => {
        const { conference } = client.readQuery({
          query: GET_CONFERENCE_CHAT,
          variables: { type: "conference" },
        });
        const conference_chats = [
          ...conference[0].conference_chats,
          confChatMessage,
        ];

        client.writeQuery({
          query: GET_CONFERENCE_CHAT,
          variables: { type: "conference" },
          data: {
            conference: [
              {
                conference_chats,
                __typename: "conference",
              },
            ],
          },
        });
      }
    );
  }, [client]);

  const handleChange = (e) => {
    setChatMessage(e.target.value);
  };

  const openQuestionTab = () => {
    if (!isQuestionOpen && confData.conference.length) {
      GET_CONFERENCE_QA_QUERY({
        variables: {
          id: confData.conference[0].id,
        },
      });
      setIsQuestionOpen(true);
    }
  };

  const closeQuestionTab = () => {
    setIsQuestionOpen(false);
  };

  const sendMessage = async () => {
    if (chatMessage && confData.conference.length) {
      SEND_CONFERENCE_CHAT_MUTATION({
        variables: {
          conference_id: confData.conference[0].id,
          message: chatMessage,
        },
        update: (store, { data }) => {
          sendConferenceMsg({
            ...data.insert_conference_chat_one,
            confId: confData.conference[0].id,
          });

          const { conference } = store.readQuery({
            query: GET_CONFERENCE_CHAT,
            variables: { type: "conference" },
          });
          const conference_chats = [
            ...conference[0].conference_chats,
            data.insert_conference_chat_one,
          ];

          store.writeQuery({
            query: GET_CONFERENCE_CHAT,
            variables: { type: "conference" },
            data: {
              conference: [
                {
                  conference_chats,
                  __typename: "conference",
                },
              ],
            },
          });
        },
      });

      setChatMessage("");
    }
  };

  const handleKeyDownSession = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const submitQuestion = async () => {
    if (qaMessage && confData.conference.length) {
      SEND_CONFERENCE_QA_MUTATION({
        variables: {
          conference_id: confData.conference[0].id,
          question: qaMessage,
        },
        update: (store, { data }) => {
          const { conference_by_pk } = store.readQuery({
            query: GET_CONFERENCE_QA,
            variables: { id: confData.conference[0].id },
          });
          const qas = [...conference_by_pk.qas, data.insert_qa_one];

          store.writeQuery({
            query: GET_CONFERENCE_QA,
            variables: { id: confData.conference[0].id },
            data: {
              conference_by_pk: {
                qas,
                __typename: "conference",
              },
            },
          });
        },
      });

      setQaMessage("");
    }
  };

  const handleQuestion = (e) => {
    setQaMessage(e.target.value);
  };

  // const onClickOfPlaylist = () => {
  //   setIsPlaylist(true);
  // };

  // const changeSource = (source) => {
  //   setSrc(source);
  // };

  // const closePlaylistModal = () => {
  //   setIsPlaylist(false);
  // };

  const openAgendaTab = () => {
    setIsAgendaOpen(true);
  };

  const closeAgendaTab = () => {
    setIsAgendaOpen(false);
  };

  const openPollTab = () => {
    if (confData.conference.length) {
      GET_CONFERENCE_POLL_QUERY({
        variables: {
          id: confData.conference[0].id,
        },
      });
      setIsPollOpen(true);
    }
  };

  const closePollTab = () => {
    setIsPollOpen(false);
  };

  const openChatTab = () => {
    if (!isChatOpen) {
      GET_CONFERENCE_CHAT_QUERY({
        variables: {
          type: "conference",
        },
      });
      setIsChatOpen(true);
    }
  };

  const closeChatTab = () => {
    setIsChatOpen(false);
  };

  const onInsertUserReward = async () => {
    try {
      await SEND_USER_REWARD({
        variables: {
          points: SESSION_POLL_REWARD + points,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onPlayerReady = (player) => {
    setPlayer(player);
  };

  const videoTimeUpdate = async (currentTime) => {
    const ct = parseInt(currentTime);
    if (ct >= confData.conference[0].playlists[0].playlist_videos[0].give_at) {
      if (!isCalled) {
        isCalled = true;
        try {
          const points_local = parseInt(localStorage.getItem("points"), 10);
          await SEND_USER_REWARD_FOR_VIDEO({
            variables: {
              points:
                confData.conference[0].playlists[0].playlist_videos[0].points +
                points_local,
              video_id:
                confData.conference[0].playlists[0].playlist_videos[0].id,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleAddToBag = async (resource_id, points_mybags) => {
    try {
      await insertUserRewardResource({
        variables: {
          resource_id,
          points: points + points_mybags,
        },
      });
    } catch (error) {
      console.log(error);
    }
    storeToMyResource({
      variables: {
        resource_id,
      },
    });
  };

  const openFullscreen = () => {
    player.requestFullscreen();
  };

  return (
    <section className=" sessionsPage h-screen flex flex-col justify-center items-center overflow-hidden ">
      <video
        loop
        autoPlay
        muted
        name="videoFrame"
        className="videoFrame object-cover"
      >
        <source
          src={`${REACT_APP_CLOUDFRONT_URL}/videos/nelson/nelson.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="sessionsContainer">
        <div className="sesImg sectionImg_1">
          {!confData && loading ? (
            <Loader />
          ) : (
            confData && (
              <>
                {confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                  ?.banners[0] ? (
                  <img
                    alt=""
                    src={
                      confData?.conference.length &&
                      confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                        ?.banners[0]
                    }
                  />
                ) : (
                  <img alt="" src={require("../images/banner_1.jpg")} />
                )}
              </>
            )
          )}
        </div>
        <div className="sesVideo seminarvideo">
          {!confData && loading ? (
            <Loader />
          ) : (
            confData && (
              <>
                {confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                  ?.url ? (
                  <>
                    <VideoPlayer
                      id="sessionVideo"
                      autoplay={true}
                      hideControls={["seekbar", "timer", "playbackrates"]}
                      onTimeUpdate={videoTimeUpdate}
                      onReady={onPlayerReady}
                      src={
                        confData?.conference.length &&
                        confData?.conference[0]?.playlists[0]
                          ?.playlist_videos[0]?.url
                      }
                    />
                    {confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                      .is_live && (
                      <div className="liveaction">
                        <i></i>
                        <span>Live</span>
                      </div>
                    )}
                  </>
                ) : (
                  <video
                    id="sessionVideo"
                    autoPlay
                    muted={true}
                    loop
                    controls={false}
                  >
                    <source
                      src={`${REACT_APP_CLOUDFRONT_URL}/videos/nelson/playlist_${code}/default.mp4`}
                      type="video/mp4"
                    />
                  </video>
                )}
              </>
            )
          )}
        </div>

        <div className="sesImg sectionImg_2">
          {!confData && loading ? (
            <Loader />
          ) : (
            confData && (
              <>
                {confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                  ?.banners[1] ? (
                  <img
                    alt=""
                    src={
                      confData?.conference?.length &&
                      confData?.conference[0]?.playlists[0]?.playlist_videos[0]
                        ?.banners[1]
                    }
                  />
                ) : (
                  <img alt="" src={require("../images/banner_2.jpg")} />
                )}
              </>
            )
          )}
        </div>
      </div>
      <div className="rightAction">
        <ul>
          <li>
            <button
              onClick={openAgendaTab}
              disabled={loading || !confData?.conference?.length}
            >
              {t("Agenda")}
            </button>
          </li>
          <li>
            <button
              onClick={openPollTab}
              disabled={loading || !confData?.conference?.length}
              className="polllink justify-between items-center"
            >
              {pollQa&&<div className={`quizlive ${pollQa ? "live" : ""}`}>
                <i></i> Live
              </div>}

              {t("Quiz")}

              <CustIcon type="coin" size="xs" className="ml-2" />
            </button>
          </li>
          <li>
            <button
              onClick={openChatTab}
              disabled={loading || !confData?.conference?.length}
              className="chatlink"
            >
              {t("Chat")}
            </button>
          </li>
          <li>
            <button
              onClick={openQuestionTab}
              disabled={loading || !confData?.conference?.length}
              className="askaquelink"
            >
              {t("Ask a Question")}
            </button>
          </li>
        </ul>
      </div>

      {isAgendaOpen && (
        <Agenda
          src={confData?.conference[0]?.resources[0]?.url}
          onCloseAgendaTab={closeAgendaTab}
          handleAddToBag={() =>
            handleAddToBag(
              confData?.conference[0]?.resources[0]?.id,
              confData?.conference[0]?.resources[0]?.points
            )
          }
          loading={loadingMeetingResource}
          loadingdata={loading}
        />
      )}

      {isPollOpen && (
        <Poll
          onClosePollTab={closePollTab}
          onInsertUserReward={onInsertUserReward}
          data={pollData?.conference_by_pk?.playlists[0]?.playlist_videos[0]}
          loading={pollLoading}
        />
      )}

      {isChatOpen && (
        <ConferenceChat
          onSendChatMessage={sendMessage}
          onCloseChatTab={closeChatTab}
          handleKeyDownSession={handleKeyDownSession}
          data={chatData}
          loading={chatLoading}
          sending={sendingChatMessage}
          message={chatMessage}
          onChange={handleChange}
        />
      )}

      {isQuestionOpen && (
        <QA
          onCloseQuestionTab={closeQuestionTab}
          onSubmitQuestion={submitQuestion}
          data={qaData}
          loading={qaLoading}
          onChange={handleQuestion}
          message={qaMessage}
          sending={sendingQA}
        />
      )}
    </section>
  );
};

const SessionsWithApollo = withApollo(Sessions);
export { SessionsWithApollo as Sessions };
