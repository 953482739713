import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactStars from "react-stars";
import Loader from "./Loader";
import CustIcon from "./Svgs";

const Feedback = ({
  onCloseFeedBack,
  loading,
  data,
  onSubmit,
  sending,
  handleKeyDownFeedback,
}) => {
  const { t } = useTranslation();
  const [review, setReview] = useState("");
  const [platformRating, setPlatformRating] = useState(0);
  const [contentRating, setContentRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [isChecked, setIsChecked] = useState(true);

  const onChangeReview = (e) => {
    setReview(e.target.value);
  };

  const onVirtualRatingChange = (ratingPlatForm) => {
    setPlatformRating(ratingPlatForm);
  };

  const onContentRatingChange = (ratingContent) => {
    setContentRating(ratingContent);
  };

  const onOverallRatingChange = (ratingOverall) => {
    setOverallRating(ratingOverall);
  };

  const handledecisionChange = (event) => {
    setIsChecked(event.currentTarget.checked);
  };

  useEffect(() => {
    if (data) {
      setPlatformRating(
        data?.feedback.length && data.feedback[0].platform_rating
      );
      setContentRating(
        data?.feedback.length && data.feedback[0].content_rating
      );
      setOverallRating(
        data?.feedback.length && data.feedback[0].overall_rating
      );
    }
  }, [data]);

  return (
    <div className="fixed modelFeedback model_blk z-10 inset-0 overflow-y-auto text-black">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onCloseFeedBack}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl lg:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 pt-4 pb-4 sm:pb-4">
            <div className="close" onClick={onCloseFeedBack}>
              <span></span>
              <span></span>
            </div>
            <div className="sm:flex flex-col sm:items-start">
              <div class="popup-header border-b border-solid w-full">
                <h3>{t("Feedback")}</h3>
              </div>
              <div className="popup-body w-full">
                {!data && loading ? (
                  <Loader />
                ) : (
                  data && (
                    <div>
                      <div>
                        <label>
                          {t("How do you rate our virtual platform?")}
                        </label>
                        <ReactStars
                          value={platformRating}
                          disabled={data?.feedback.length}
                          count={5}
                          size={50}
                          color2={"#ffd700"}
                          onChange={onVirtualRatingChange}
                          half={false}
                          edit={
                            data?.feedback.length &&
                            data.feedback[0].platform_rating
                              ? false
                              : true
                          }
                          className="mb-5 rating"
                        />
                      </div>
                      <div>
                        <label>
                          {t(
                            "How do you rate our scientific content and topics?"
                          )}
                        </label>
                        <ReactStars
                          value={contentRating}
                          disabled={data?.feedback.length}
                          count={5}
                          size={50}
                          color2={"#ffd700"}
                          onChange={onContentRatingChange}
                          half={false}
                          edit={
                            data?.feedback.length &&
                            data.feedback[0].content_rating
                              ? false
                              : true
                          }
                          className="mb-5 rating"
                        />
                      </div>
                      <div>
                        <label>
                          {t(
                            "How will you rate your overall experience of iamicon 2021?"
                          )}
                        </label>
                        <ReactStars
                          value={overallRating}
                          disabled={data?.feedback.length}
                          count={5}
                          size={50}
                          color2={"#ffd700"}
                          onChange={onOverallRatingChange}
                          half={false}
                          edit={
                            data?.feedback.length &&
                            data.feedback[0].overall_rating
                              ? false
                              : true
                          }
                          className="mb-5 rating"
                        />
                      </div>
                      <div>
                        <label>
                          {t("Will you attend the next schedule of iamicon?")}
                        </label>
                        <div className="switchSchedule flex items-center py-3 mb-3">
                          <div className="cont font-bold text-black pr-3">
                            {t("No")}
                          </div>
                          <label className="switch">
                            <input
                              defaultChecked={
                                data?.feedback.length &&
                                data.feedback[0].will_attend
                              }
                              disabled={data?.feedback.length}
                              onChange={handledecisionChange}
                              className="switch__input"
                              type="checkbox"
                              checked={isChecked}
                              data-theme-toggle
                            />
                            <span className="switch__slider"></span>
                          </label>
                          <div className="cont font-bold text-black pl-3">
                            {t("Yes")}
                          </div>
                        </div>
                      </div>
                      <div className="feedbackTextarea">
                        <textarea
                          type="text"
                          name="feedback"
                          onChange={onChangeReview}
                          onKeyDown={handleKeyDownFeedback}
                          className="msg-input w-full border border-solid mb-3 rounded p-3 leading-none"
                          placeholder={t("Give Your Feedback")}
                          disabled={data?.feedback.length}
                          defaultValue={
                            data?.feedback.length
                              ? data.feedback[0].comments
                              : ""
                          }
                          required={true}
                        />
                      </div>
                      {!data?.feedback.length ? (
                        <button
                          className="btn-submit"
                          disabled={data?.feedback.length}
                          onClick={() =>
                            onSubmit(
                              review,
                              platformRating,
                              contentRating,
                              overallRating,
                              isChecked
                            )
                          }
                        >
                          {sending ? `${t("Sending")}` : `${t("Send")}`}
                          <CustIcon type="coin" size="xs" className="ml-3" />
                        </button>
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
